export default {
  name: "CardCommonEventSummary",
  props: {
    event: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    return (
      <div class="c-myreserve-candidate">
        {!this.editable && (
          <p class="c-myreserve-candidate__tooltips c-label-tooltips-item">
            <span class="c-label-tooltips">{this.event.googleCal.title}</span>
            <p class="c-myreserve-candidate-ttl c-label c-candidate">
              {this.event.googleCal.title}
            </p>
          </p>
        )}
        {this.editable && (
          <input
            type="text"
            class="c-myreserve-candidate-ttl c-label c-candidate"
            value={this.event.googleCal.title}
          />
        )}
      </div>
    );
  },
};
