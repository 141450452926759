export default {
  name: "NumberSelect",
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    filter: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  mounted() {
    this.selected = this.value;
  },
  beforeUpdate() {
    this.selected = this.value;
  },
  render() {
    const options = [];
    if (this.placeholder) {
      options.push(<option value="">{this.placeholder}</option>);
    }
    options.push(
      ...this.options.map((l) => (
        <option value={l.value}>
          {l.label.toString().padStart(2, " ").replace(" ", "\xA0")}
        </option>
      ))
    );

    return (
      <select
        vModel={this.selected}
        data-selected={this.selected}
        onInput={(e) => {
          this.$listeners.input(Number(e.target.value));
        }}
      >
        {options}
      </select>
    );
  },
};
