import parseDate from "./parseDate";

/**
 * YYYY-MM-DD HH:MM:SS
 * @param {Date} target
 * @returns {string}
 */
export default function (target) {
  const { year, month, date, hours, minutes, seconds } = parseDate(target);
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}
