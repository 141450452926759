import CandidateRoom from "./candidate/CandidateRoom";

export default {
  name: "TheModalCompleteReserve",
  methods: {
    hide() {
      this.$store
        .dispatch("loadEvents")
        .then(() => this.$modal.hide("TheModalCompleteReserve"));
    },
  },
  render() {
    const status = this.$store.state.completeTxt;
    const candidate = this.$store.getters.selectedCandidates;
    return (
      <modal
        class="p-modal__mail-info"
        name="TheModalCompleteReserve"
        onBefore-close={() => {
          this.$store.state.candidateRooms = [];
          this.$store.commit("resetModal");
        }}
        width="100%"
        height="auto"
        pivotX={0.5}
        pivotY={0.5}
      >
        <div class="p-select-room u-send">
          <div slot="top-right">
            <button class="c-modal-cancel__btn u-send" onClick={this.hide} />
          </div>
          <p
            class="c-select-room__ttl c-complete-ttl"
            style="padding: 0px 15px;"
          >
            {status === "予約"
              ? "会議室を予約しました。"
              : "会議室を仮予約しました。"}
          </p>
          {status === "予約" && (
            <div class="c-select-room__box-ttl" style="padding: 0px 15px;">
              ゲストに予約内容が送信されました。
            </div>
          )}
          {status === "仮予約" && (
            <div class="c-select-room__box-ttl">
              ゲストに候補日についてのアンケートメールが送信されました。
              <br />
              アンケート結果を基に、予約を確定させてください。
              <br />
              アンケートの〆切は、1週間です。
            </div>
          )}
          <div class="c-select-room__main-wrap">
            <ul class="c-select__ttl-list">
              {candidate.map((candidate, index) => (
                <li class="is-active">
                  <span>{["A", "B", "C"][index]}</span>
                </li>
              ))}
            </ul>
            <ul class="c-select-list c-modal-list u-send">
              {candidate.map((candidate, index) => (
                <CandidateRoom candidate={candidate} index={index + 1} />
              ))}
            </ul>
          </div>
        </div>
      </modal>
    );
  },
};
