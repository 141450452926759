export default {
  name: "CalendarSelectedRoomSpace",
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  render() {
    const candidate = this.candidate;
    const start = candidate.start;
    const end = candidate.end;
    const startMin = start.getHours() * 60 + start.getMinutes();
    const endMin = Math.max(
      (start.getDate() === end.getDate() ? end.getHours() : 24) * 60 +
        end.getMinutes(),
      startMin + 30
    );
    const style = {
      position: "absolute",
      top: "50%",
      bottom: "50%",
      left: `calc(${(startMin / 1440) * 100}% - 1px)`,
      right: `calc(${(1 - endMin / 1440) * 100}% + 1px)`,
      marginTop: "-17.5px",
      pointerEvents: "none",
      willChange: "left,right",
    };

    return (
      <div
        class="is-selected-item"
        style={style}
        data-start={candidate.start_time}
        data-end={candidate.end_time}
      />
    );
  },
};
