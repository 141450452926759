import getSelectValue from "functions/time/getSelectValue";

export default function (event) {
  const startTime = event.start_time.replace(/-/g, "/");
  const endTime = event.end_time.replace(/-/g, "/");
  const start = getSelectValue(new Date(startTime));
  const end = getSelectValue(new Date(endTime));
  const [inUsedHour, inUsedMin] = startTime
    .slice(startTime.indexOf(" ") + 1)
    .split(":");
  const whichStart = start.label
    ? start
    : { label: `${inUsedHour}:${inUsedMin}` };
  return { startTime: startTime, reStart: whichStart, reEnd: end };
}
