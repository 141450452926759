import CardCommonRoom from "./common/CardCommonRoom";
import CardCommonEvent from "./common/event/CardCommonEvent";
import CardCommonButton from "./common/CardCommonButton";
import CardCommonEditButton from "./common/CardCommonEditButton";
import CardTypesStandByTimeAlert from "./types/standBy/CardTypesStandByTimeAlert";
import standbyTtlImage from "assets/standby__ttl.png";

export default {
  name: "CardStandBy",
  props: {
    event: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    beforeLeave: {
      type: Function,
      required: true,
    },
    leave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    before(el) {
      if (this.event.status !== "キャンセル" && this.event.status !== "完了") {
        return el.classList.remove("animated");
      }
      this.beforeLeave(this.$el);
    },
    after() {
      if (this.event.status !== "キャンセル" && this.event.status !== "完了")
        return;
      const baseSchedule = JSON.parse(this.$store.state.stockJsonReserve);
      const newSchedule = baseSchedule.filter(
        ({ uuid }) => uuid !== this.event.mySch.uuid
      );
      this.$store.state.stockJsonReserve = JSON.stringify(newSchedule);
      document
        .querySelector(".c-myreserve-box.is-standby")
        ?.classList.add("checkInBtnActive");
      this.leave();
    },
  },
  render() {
    const edit = () => {
      if (this.edit) {
        this.edit = false;
      } else {
        document
          .querySelector(".editActive .c-myreserve-edit__btn.is-active")
          ?.click();
        document
          .querySelector(".tentativeEditActive .c-myreserve-cancel__btn")
          ?.click();
        this.edit = !this.edit;
        this.$store.state.editInUsedMode = true;
      }
    };
    return (
      <transition
        name="standByList"
        tag="div"
        leave-active-class="animated fadeOutLeft"
        onBefore-leave={this.before}
        onAfter-leave={this.after}
      >
        {this.event.status === "予約" && (
          <div
            class={[
              "c-myreserve-box",
              this.type === "使用中" ? "is-inuse" : "is-standby",
              !this.index && "checkInBtnActive",
              this.edit && "editActive",
            ]}
          >
            <p class="c-myreserve-cancel__btn" />
            <CardCommonEditButton onClick={edit} editable={this.edit} />
            <div class="c-myreserve-ttl__wrap">
              <p class="c-myreserve-main__ttl">
                <img src={standbyTtlImage} alt="standby" />
              </p>
              <p class="c-myreserve-sub__ttl">チェックイン待ち</p>
            </div>
            <div class="c-myreserve__sub">
              {!this.edit && !this.index && (
                <CardTypesStandByTimeAlert event={this.event} type="予約" />
              )}
              <CardCommonEvent
                event={this.event}
                index={this.index}
                editable={this.edit}
              />
              <CardCommonRoom
                event={this.event}
                index={this.index + 1}
                editable={this.edit}
              />
              <CardCommonButton
                event={this.event}
                status="standBy"
                index={this.index}
                editable={this.edit}
              />
            </div>
          </div>
        )}
      </transition>
    );
  },
};
