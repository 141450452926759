export default {
  name: "CardTypesTentativeTitle",
  props: {
    cancel: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    return (
      <div class="c-myreserve-ttl__wrap">
        {this.cancel && (
          <p class="c-select-room__ttl c-complete-ttl">
            仮予約をキャンセルしますか？
          </p>
        )}
        {!this.cancel && (
          <p class="c-myreserve-main__ttl">
            <img
              src="/static/myreserve/reservation__ttl.png"
              alt="reservation"
            />
          </p>
        )}
        {!this.cancel && <p class="c-myreserve-sub__ttl">仮予約</p>}
      </div>
    );
  },
};
