import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import updateDate from "utils/updateDate";
import TimeSelect from "components/TimeSelect";

export default {
  name: "CandidateRoom",
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      plans: false,
    };
  },
  mounted() {
    this.cp();
  },
  updated() {
    this.cp();
  },
  methods: {
    cp() {
      const { start } = this.candidate;
      const list = document.querySelector(
        `.c-select__ttl-list > [data-index="${this.index}"]`
      );
      if (start < new Date()) {
        list && list.classList.add("is-old");
        this.$refs.root.classList.add("is-old");
      } else {
        list && list.classList.remove("is-old");
        this.$refs.root.classList.remove("is-old");
      }
    },
  },
  render() {
    const room = this.$store.getters.getRoomById(this.candidate.id);
    const { start, end } = this.candidate;

    const handleInputCalendar = (date) => {
      updateDate(date, start, end);
      const recommend = Object.assign({}, this.candidate, { start, end });
      this.$store.commit("updateRecommend", { index: this.index, recommend });
    };
    const handleInputTime = (selected, target) => {
      const index = this.index;
      const [hour, min] = selected.value.split(":").map(Number);
      target.setHours(hour);
      target.setMinutes(min);
      const recommend = Object.assign({}, this.candidate, { start, end });
      this.$store.commit("updateRecommend", { index, recommend });
      this.plans = eventsDuplication(this.candidate);
    };
    const eventsDuplication = (candidate) => {
      const { start, end, id } = candidate;
      const ev = this.$store.state.events.filter(
        (_this) => id === _this.room_id
      );
      return ev[0].list.find(
        (n) =>
          (new Date(n.start_time) < start && new Date(n.end_time) > start) ||
          (start < new Date(n.start_time) && end > new Date(n.end_time)) ||
          (new Date(n.start_time) < end && new Date(n.end_time) > end)
      );
    };
    const recommendIndex = this.$store.state.recommendIndex;
    const searchAnimate = (type) => {
      document.querySelectorAll(".c-select-list > li").forEach((n, i) => {
        if (!n.classList.contains("is-selected")) {
          n.classList.remove("animateTransist");
          n.classList.add("animateTopUp");
        }
      });
      setTimeout(() => {
        document.querySelectorAll(".c-select-list > li").forEach((n) => {
          n.classList.remove("animateTopUp");
          n.classList.add("animateTransist");
        });
        if (type === "reset") {
          this.$store.state.searchCandidateIndexReset = false;
        } else {
          this.$store.state.searchCandidateIndex = false;
        }
      }, 300);
    };
    if (this.$store.state.searchCandidateIndex) searchAnimate("recommend");
    if (this.$store.state.searchCandidateIndexReset) searchAnimate("reset");
    setTimeout(() => {
      if (document.querySelector(".abcList-ul")) {
        if (this.$store.state.reserveModalState) {
          document
            .querySelectorAll(".abcList-ul > li")
            .forEach((n) => (n.style.height = this.$el.clientHeight + "px"));
        } else {
          document.querySelectorAll(".abcList-ul > li")[
            this.index
          ].style.height = this.$el.clientHeight + "px";
        }
      }
    }, 100);
    return (
      <li
        class={[
          "u-ttl0" + (this.index + 1),
          { "is-selected": this.candidate.selected },
          "selected-area",
          { "is-old": start < new Date() },
          { "is-booked": this.plans },
        ]}
        ref="root"
        onClick={this.$listeners?.click || (() => {})}
        style="border: none; padding: 0; border-radius: none;"
        onTransitionend={() =>
          document
            .querySelector(".c-select-room__main-wrap")
            .classList.remove("animateOverWrap")
        }
      >
        <transition
          name="list-li"
          leave-active-class="animated fadeOutLeft"
          onAfter-leave={() => {
            this.$store.commit("removeRecommend", this.index);
            this.$store.state.recommendIndex = "";
            this.$nextTick(() => {
              document
                .querySelectorAll(".c-select-list > li")
                .forEach((n, i) => {
                  if (recommendIndex <= i) n.classList.add("animateTopUp");
                });
            });
            setTimeout(() => {
              document.querySelectorAll(".c-select-list > li").forEach((n) => {
                n.classList.remove("animateTopUp");
                n.classList.add("animateTransist");
                this.$store.state.candidateIndex = "";
              });
            }, 300);
          }}
        >
          {recommendIndex !== this.index && (
            <div
              class="test"
              style="display: flex; border-radius: 15px; padding: 7px 0px;"
            >
              <div style="display: flex;">
                <div class="c-select-candidate selected-area">
                  <p
                    class="c-select-candidate-ttl c-label c-candidate in-first is-active"
                    style="padding-bottom: 0;"
                  >
                    {this.candidate.selected
                      ? {
                          list: "おすすめの会議室",
                          drag: "会議室スケジュール",
                        }[this.candidate.type] + "から選択"
                      : "おすすめ会議室"}
                  </p>
                </div>
                <div class="c-select-info selected-area candidateRoomDetail-box">
                  <p class="c-select-info__tooltips c-label-tooltips-item selected-area">
                    <span class="c-label-tooltips selected-area">
                      {room.name}
                    </span>
                    <p
                      class="c-select-info__room"
                      style="word-break: break-all;"
                      onClick={(e) =>
                        e.target.offsetWidth !== e.target.scrollWidth &&
                        e.target.parentNode.classList.toggle("is-active")
                      }
                    >
                      {room.name}
                    </p>
                  </p>
                  {room.tags.map((tag) => (
                    <p class="c-select-info__name selected-area">
                      {this.$store.getters.getTagNameById(tag)}
                    </p>
                  ))}
                  <p class="c-select-info__people selected-area">
                    <span class="c-select-info__people-number selected-area">
                      {room.capacity}
                    </span>
                    人
                  </p>
                </div>
                <div class="c-select-daytime selected-area">
                  <div class="c-select-daytime__date-label selected-area">
                    <Datepicker
                      ref="picker"
                      class="c-select-daytime__date"
                      language={ja}
                      value={this.candidate.start}
                      format="yyyy年MM月dd日"
                      onInput={handleInputCalendar}
                    />
                  </div>
                  <div class="c-select-daytime__time-wrap selected-area">
                    <TimeSelect
                      class="c-select-daytime__time-in"
                      value={start}
                      onInput={(selected) => handleInputTime(selected, start)}
                    />
                    <span class="c-select-daytime__time-while">-</span>
                    <TimeSelect
                      class="c-select-daytime__time-out"
                      value={end}
                      onInput={(selected) => handleInputTime(selected, end)}
                    />
                  </div>
                </div>
              </div>
              <div
                class="c-select-cancel__btn-wrap"
                onClick={() => {
                  document
                    .querySelector(".c-select-room__main-wrap")
                    .classList.add("animateOverWrap");
                  this.$store.state.recommendIndex = this.index;
                  this.$el.parentNode
                    .querySelectorAll("li")
                    .forEach((n) => n.classList.remove("animateTransist"));
                }}
              >
                <p class="c-select-cancel__btn" />
              </div>
              {start < new Date() && (
                <p class="notice-time">過去の時間は予約できません。</p>
              )}
              {start > new Date() && this.plans && (
                <p class="notice-booked">入力した日程はすでに埋まっています</p>
              )}
            </div>
          )}
        </transition>
      </li>
    );
  },
};
