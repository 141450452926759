import { Workbox, messageSW } from "workbox-window";

if (process.env.NODE_ENV === "production") {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox("/sw.js");
    let registration;

    const showSkipWaitingPrompt = () => {
      wb.addEventListener("controlling", (event) => {
        window.alert("新しいバージョンが配信されました。");
        window.location.reload();
      });

      if (registration && registration.waiting) {
        messageSW(registration.waiting, { type: "SKIP_WAITING" });
      } else {
        console.info("not", registration, registration?.waiting);
      }
    };

    wb.addEventListener("waiting", showSkipWaitingPrompt);
    wb.addEventListener("externalwaiting", showSkipWaitingPrompt);

    wb.register().then((r) => (registration = r));
  }
}
