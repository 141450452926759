import SelectGuestBox from "components/SelectGuestBox";
import calendarEvents from "states/calendarEvents";
import { findColorByCode } from "data/colors";

export default {
  name: "CardTypesTentativeGuests",
  props: {
    event: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      users: [...new Set(this.event.googleCal.guest)].map(({ email }) =>
        calendarEvents.get(email)
      ),
      color: findColorByCode(this.event.googleCal.color).color || 1,
    };
  },
  methods: {
    handleInput(input) {
      if (
        this.users.some(
          (calendarEvent) => calendarEvent.calendarId === input.email
        )
      )
        return alert("すでに追加済みです。");
      this.users.push(calendarEvents.get(input.email, input.name));
    },
    handleDelete(calendarEvent) {
      this.users = this.users.filter((ce) => ce !== calendarEvent);
    },
  },
  render() {
    const owner = calendarEvents.getNew(
      this.event.googleCal.creator,
      this.event.mySch.schedule.owner_color_code
    );
    const resources = this.event.mySch.schedule.events.map(
      (ev) =>
        this.$store.state.rooms.find((room) => +ev.room_id === room.id)
          .resource_id
    );
    return (
      <div
        class="c-myreserve-join__wrap"
        style="margin-left: 0; margin-top: 0; width: 100%;"
      >
        <p class="c-myreserve-join__img">
          <img src="static/myreserve/guest-icon.png" alt="" />
        </p>
        <div class="c-myreserve__guest-join__box" style="width: 100%;">
          {this.editable && (
            <SelectGuestBox
              ref="select"
              style="color: #666;"
              class="c-guest-join__input guestInput-select"
              onInput={this.handleInput}
            />
          )}
          <ul class="c-guest-join__output" style="margin-top: 0;">
            <li style="justify-content: left;">
              <p
                class="c-guest-join__color"
                style={{ background: owner.color.color }}
              />
              <p class="c-guest__tooltips c-label-tooltips-item">
                <span class="c-label-tooltips selected-area">{owner.name}</span>
                <span
                  class="c-guest-join__name"
                  onClick={(e) =>
                    e.target.offsetWidth !== e.target.scrollWidth &&
                    e.target.parentNode.classList.toggle("is-active")
                  }
                >
                  {owner.name}
                </span>
              </p>
              <p class="c-guest__tooltips c-guest__Rset-tooltips c-label-tooltips-item">
                <span class="c-label-tooltips  selected-area">
                  {owner.calendarId}
                </span>
                <span
                  class="c-guest-join_email"
                  onClick={(e) =>
                    e.target.offsetWidth !== e.target.scrollWidth &&
                    e.target.parentNode.classList.toggle("is-active")
                  }
                >
                  {owner.calendarId}
                </span>
              </p>
            </li>
            {this.users
              .filter(
                (calendarEvent) =>
                  this.event.googleCal.creator !== calendarEvent.calendarId &&
                  !resources.includes(calendarEvent.calendarId)
              )
              .map((calendarEvent) => (
                <li
                  style={{ justifyContent: "left" }}
                  data-email={calendarEvent.calendarId}
                  data-color={calendarEvent.color.color}
                >
                  <p
                    class="c-guest-join__color"
                    style={{ background: calendarEvent.color.color }}
                  />
                  <p class="c-guest__tooltips c-label-tooltips-item">
                    <span class="c-label-tooltips selected-area">
                      {calendarEvent.name}
                    </span>
                    <span
                      class="c-guest-join__name"
                      onClick={(e) =>
                        e.target.offsetWidth !== e.target.scrollWidth &&
                        e.target.parentNode.classList.toggle("is-active")
                      }
                    >
                      {calendarEvent.name}
                    </span>
                  </p>
                  <p class="c-guest__tooltips c-guest__Rset-tooltips c-label-tooltips-item">
                    <span class="c-label-tooltips  selected-area">
                      {calendarEvent.calendarId}
                    </span>
                    <span
                      class="c-guest-join_email"
                      onClick={(e) =>
                        e.target.offsetWidth !== e.target.scrollWidth &&
                        e.target.parentNode.classList.toggle("is-active")
                      }
                    >
                      {calendarEvent.calendarId}
                    </span>
                  </p>
                  {this.editable && (
                    <p
                      class="c-guest-cancel__btn"
                      style="z-index: 50;"
                      onClick={() => this.handleDelete(calendarEvent)}
                    />
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  },
};
