import ParseDate from "utils/parseDate";
import { putSchedules, getSchedules } from "network/server";
import TimeSelect from "components/TimeSelect";

export default {
  name: "TheModalCardInUsedExtend",
  methods: {
    hide() {
      this.$modal.hide("TheModalCardInUsedExtend");
    },
    classHide() {
      document
        .querySelector(".c-myreserve-box.is-inuse")
        .classList.remove("extentTime");
    },
  },
  render() {
    const inUsed = this.$store.state.storeInusedEvents;
    const onEnd = (e) => {
      this.$store.commit("updateSelectorValue", {
        type: "end",
        data: { label: e.label, value: e.label },
      });
    };
    const { year, month, date } = ParseDate(new Date());
    let useStart = this.$store.state.selecterValue.st.value;
    let useEnd = this.$store.state.selecterValue.ed.value;

    if (!inUsed && !useStart) {
      return;
    }

    if (!useStart) {
      useStart = inUsed.start_time;
    } else {
      useStart = `${year}-${month}-${date} ${useStart}:00`;
    }
    if (!useEnd) {
      useEnd = inUsed.end_time;
    } else {
      const hours = useEnd.slice(0, useEnd.indexOf(":")).padStart(2, 0);
      const mins = useEnd.slice(useEnd.indexOf(":") + 1);
      useEnd = `${year}-${month}-${date} ${hours}:${mins}:00`;
    }

    const extension = () => {
      this.$store.state.isLoading = true;
      const postData = {
        title: document.querySelector(".extentTime .c-myreserve-candidate-ttl")
          .innerText,
        detail: document.querySelector(".extentTime .c-plan-textarea")
          .innerText,
        start_time: useStart,
        end_time: useEnd,
      };
      this.$store.commit("updateCalenderscedules", {
        type: "updateInUsed",
        data: postData,
      });
      putSchedules({
        rooms: [
          {
            event_id: inUsed.eventId,
            update: {
              end_time: useEnd,
            },
          },
        ],
      })
        .then((r) => (r.success ? Promise.resolve(r) : Promise.reject(r)))
        .then(() => {
          getSchedules()
            .then(() => {
              this.$store.dispatch("loadEvents");
              this.$modal.hide("TheModalCardInUsedExtend");
              this.$store.state.isLoading = false;
            })
            .catch((e) => {
              if (e.errors) {
                alert("通信エラーが発生しました。");
              }
              this.$store.state.isLoading = false;
            });
        });
    };
    const startFix = () => {
      const { hours, minutes } = ParseDate(
        new Date(useStart.replace(/-/g, "/"))
      );
      return `${hours}:${minutes}`;
    };
    return (
      <modal
        class="p-extension__modal"
        name="TheModalCardInUsedExtend"
        pivotX={0.5}
        height="auto"
        width="90%"
        onBefore-close={this.classHide}
      >
        <div class="p-myreserve-modal">
          <div slot="top-right">
            <button class="c-modal-cancel__btn" onClick={this.hide} />
          </div>
          <div class="c-myreserve-modal__content">
            <p class="c-myreserve-modal__ttl">利用時間を選択してください</p>
            <div class="c-myreserve-modal__select-time">
              <p class="c-myreserve-modal__select-input c-myreserve-modal__select-time__in">
                {startFix()}
              </p>
              <span class="c-myreserve-modal__select-time__while">〜</span>
              <TimeSelect
                class="c-myreserve-modal__select-input c-myreserve-modal__select-time__out"
                value={new Date(useEnd.replace(/-/g, "/"))}
                onInput={onEnd}
                start={new Date(useStart.replace(/-/g, "/"))}
              />
            </div>
            <div class="c-myreserve-modal-btn__wrap is-inuse">
              <p class="c-btn c-myreserve-modal-btn" onClick={extension}>
                延長
              </p>
            </div>
          </div>
        </div>
      </modal>
    );
  },
};
