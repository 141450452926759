const profile = {};

export const getEmail = () => {
  if (!profile.email) {
    profile.email = gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getBasicProfile()
      .getEmail();
  }

  return profile.email;
};
