import VueSelect from "vue-select";

export default {
  name: "SelectGuestBox",
  render() {
    const options = this.$store.state.staffList.map((staff) => {
      return { email: staff.email, name: staff.name };
    });

    const search = (options, search) => {
      if (search.length < 1) return options;
      const searchResult = options.filter(
        (option) =>
          option.email.indexOf(search) !== -1 ||
          option.name.indexOf(search) !== -1
      );
      if (searchResult.length !== 0) return searchResult;
      return search.match(/.+@.+\..+/) !== null
        ? [{ email: search, name: search }]
        : [];
    };

    const scopedSlots = {
      option: ({ email, name }) => (
        <div>
          <div class="c-guest-join__box">
            <p class="c-guest-join__name">{name}</p>
            <p class="c-guest-join__mail">{email}</p>
          </div>
        </div>
      ),
    };

    return (
      <VueSelect
        value=""
        placeholder="ゲストを追加"
        options={options}
        onInput={this.$listeners.input}
        filter={search}
        label="name"
        scopedSlots={scopedSlots}
      >
        <div slot="no-options">
          <p>
            候補が見つかりませんでした。
            <br />
            候補以外の対象を指定する場合はE-mailアドレスを入力してください。
          </p>
        </div>
      </VueSelect>
    );
  },
};
