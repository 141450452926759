import TheSideMenuOpenButton from "views/TheSideMenuOpenButton";
import TheCards from "./TheCards";
import TheModalMyReserveCheck from "./TheModalMyReserveCheck";
import TheModalCardInUsedExtend from "./card/types/inUsed/TheModalCardInUsedExtend";

export default {
  name: "TheMyReserve",
  data() {
    return {
      pushing: false,
    };
  },
  mounted() {
    this.tabs = this.$parent.$refs.tabs.$refs.tabs;
    this.setPanelListHeight();
  },
  updated() {
    this.setPanelListHeight();
  },
  methods: {
    setPanelListHeight() {
      if (this.$refs.panelList) {
        this.$refs.panelList.style.top = this.tabs.clientHeight + "px";
      }
    },
  },
  render() {
    if (!this.$store.state.loginStatus) return <div />;
    return (
      <div>
        <div class="u-myreserve">
          <ul ref="panelList" class="c-panel_list">
            <li>
              <TheSideMenuOpenButton onClick={this.$listeners.click} />
            </li>
          </ul>
        </div>
        <TheCards />
        <div class="p-myreserve-info__modal">
          <TheModalMyReserveCheck />
          <TheModalCardInUsedExtend />
        </div>
      </div>
    );
  },
};
