import Vue from "vue";
import Vuex from "vuex";
import arrayShuffle from "./utils/arrayShuffle";
import Format from "./utils/formatDate";
import today from "./utils/today";
import { getSchedules } from "./network/server";

import PublicHoliday from "./classes/PublicHoliday";
import getInitSearchTime from "./functions/time/getInitSearchTime";
import parseDate from "./utils/parseDate";

Vue.use(Vuex);

const floor = {
  names: ["All"],
  values: ["All"],
  index: 0,
  get name() {
    return this.names[this.index];
  },
  get value() {
    return this.values[this.index];
  },
  shift() {
    this.index = this.index === this.names.length - 1 ? 0 : this.index + 1;
    return this;
  },
  clear() {
    this.index = 0;
    return this;
  },
  updateFloors(floors) {
    this.names = ["All", ...floors.map((f) => f + "F")];
    this.values = ["All", ...floors];
  },
};

const clearRecommend = () => {
  store.state.candidateRooms = store.state.candidateRooms.filter(
    (room) => room?.selected
  );
};

const store = new Vuex.Store({
  state: {
    canAnimate: true,
    isLoading: false,
    my: [],
    defaultDate: today(),
    capacity: -1,
    sendStartDays: "",
    email: "",
    color: { id: 11, code: "#d50001" },
    meetflg: false,
    visibility: "default",
    transparency: "opaque",
    meetid: "",
    rooms: [],
    tags: [],
    requested: [],
    events: [],
    candidateRooms: [],
    selectedTags: [],
    reversedTag: false,
    searchDateStart: today(),
    searchDateStartTmp: today(),
    daysPush: 1,
    searchDateEnd: null,
    editSearchDate: false,
    searchTimeStart: getInitSearchTime().value,
    searchTimeEnd: null,
    editSearchTime: false,
    isOverSearchDate: false,
    isOverSearchTime: false,
    rangeSearchHours: -1,
    rangeSearchMinutes: -1,
    staffList: [],
    guests: [],
    calenderDetail: [],
    removedRecommends: [],
    floor: floor.name,
    reseringDate: { today: new Date() },
    modalBoolen: "",
    options: [],
    modalForBtn: "",
    selecterValue: {
      st: "",
      ed: "",
    },
    loginStatus: false,
    getStandByList: {
      update: {},
      status: false,
    },
    standByEventCheckIn: false,
    inUsedCheckOut: "",
    conectError: false,
    loginError: {},
    openModal: false,
    reserveModalState: false,
    transitionTentativeId: false,
    tentativeUpdateId: [],
    tentativeUpdateInUsedId: [],
    storeInusedEvents: "",
    storeReservationEvents: [],
    storeReservationUpdateState: false,
    tentativeCancelId: [],
    storeTentativeEvents: [],
    planTxtNone: false,
    completeTxt: "",
    editInUsedMode: false,
    stockJsonReserve: "",
    stockJsonTentative: "",
    recommendIndex: "",
    candidateIndex: "",
    searchCandidateIndex: "",
    searchCandidateIndexReset: "",
    publicHoliday: null,
    isPublicHoliday: false,
  },
  mutations: {
    updateCalenderscedules(state, { type, data }) {
      if (type === "reserveCancel") {
        const event = state.storeReservationEvents.find(
          (event) => data === event.calendar_id
        );
        if (event) event.status = "キャンセル";
      } else if (type === "updateReserve") {
        const start = Format(new Date(data[0].start));
        const reStart = start.slice(start.indexOf(" "));
        const end = Format(new Date(data[0].end));
        const reEnd = end.slice(end.indexOf(" "));
        state.storeReservationEvents.forEach((_this, i) => {
          if (data[1] === _this.calendar_id) {
            _this.start_time = Format(new Date(data[0].yearCheck + reStart));
            _this.end_time = Format(new Date(data[0].yearCheck + reEnd));
            _this.googleCal.title = data[0].title;
            _this.googleCal.description = data[0].detail;
            if (_this.mySch) {
              _this.mySch.schedule.events.forEach((n) => {
                if (n.calendar_id === data[1]) {
                  n.start_time = Format(new Date(data[0].yearCheck + reStart));
                  n.end_time = Format(new Date(data[0].yearCheck + reEnd));
                }
              });
            }
          }
        });
      } else if (type === "updateTentative") {
        const start = Format(new Date(data[0].start));
        const reStart = start.slice(start.indexOf(" "));
        const end = Format(new Date(data[0].end));
        const reEnd = end.slice(end.indexOf(" "));
        state.storeTentativeEvents.forEach((_this, i) => {
          _this.mySch.schedule.events.forEach((n) => {
            if (n.calendar_id === data[1]) {
              _this.start_time = Format(new Date(data[0].yearCheck + reStart));
              _this.end_time = Format(new Date(data[0].yearCheck + reEnd));
              _this.googleCal.title = data[0].title;
              _this.googleCal.description = data[0].detail;
              n.start_time = Format(new Date(data[0].yearCheck + reStart));
              n.end_time = Format(new Date(data[0].yearCheck + reEnd));
            }
          });
        });
      } else if (type === "cancelTentative") {
        state.storeTentativeEvents.forEach((_this, i) => {
          if (_this.mySch) {
            _this.mySch.schedule.events.forEach((n) => {
              const test = data.find((id) => id === n.calendar_id);
              if (test) n.status = "キャンセル";
            });
          }
        });
      } else if (type === "cancelAllTentative") {
        state.storeTentativeEvents.forEach((_this, i) => {
          if (_this.mySch) {
            _this.mySch.schedule.events.forEach((n) => {
              if (n.calendar_id === data[0]) _this.status = "キャンセル";
            });
          }
        });
      } else if (type === "setInUsed") {
        state.storeReservationEvents.forEach((_this) => {
          if (data === _this.calendar_id) {
            _this.status = "使用中";
            state.storeInusedEvents = _this;
            state.storeInusedEvents.status = "使用中";
          }
        });
      } else if (type === "updateInUsed") {
        const start = data.start_time || data.start;
        const end = data.end_time || data.end;
        state.storeInusedEvents.googleCal.title = data.title;
        state.storeInusedEvents.googleCal.description = data.detail;
        if (data.guests) state.storeInusedEvents.googleCal.guest = data.guests;
        state.storeInusedEvents.start_time = Format(
          new Date(start.replace(/-/g, "/"))
        );
        state.storeInusedEvents.end_time = Format(
          new Date(end.replace(/-/g, "/"))
        );
      }
    },
    guests(state, email) {
      state.guests.push(email);
    },
    removeGuest(state, guest) {
      state.guests = state.guests.filter((g) => g !== guest);
    },
    openModal(state, bool) {
      state.openModal = bool;
    },
    updateInUsedCheckOut(state, data) {
      state.inUsedCheckOut = data;
    },
    updateConnectError(state, data) {
      state.conectError = data;
    },
    updateLoginError(state, data) {
      if (data === "succeed") state.loginError = true;
      state.loginError = data;
    },
    updateSelectorValue(state, { type, data }) {
      if (type === "start") state.selecterValue.st = data;
      if (type === "end") state.selecterValue.ed = data;
      if (type === "remove") {
        state.selecterValue.st = {};
        state.selecterValue.ed = {};
      }
    },
    updateModalBtn(state, type) {
      state.modalForBtn = type;
    },
    options(state, res) {
      const options = res.data;
      if (
        state.rangeSearchHours ===
          state.options.find((op) => op.name === "所要時間")?.value ||
        state.rangeSearchHours === -1
      )
        state.rangeSearchHours = options.find(
          (op) => op.name === "所要時間"
        ).value;
      if (
        state.rangeSearchMinutes ===
          state.options.find((op) => op.name === "所要時間・分")?.value ||
        state.rangeSearchMinutes === -1
      )
        state.rangeSearchMinutes = options.find(
          (op) => op.name === "所要時間・分"
        )?.value;
      if (
        state.capacity ===
          state.options.find((op) => op.name === "収容人数")?.value ||
        state.capacity === -1
      )
        state.capacity = options.find((op) => op.name === "収容人数").value;

      state.options = options;
      store.state.isPublicHoliday = state.options.find(
        (op) => op.name === "休日設定"
      ).value.public;
      if (store.state.isPublicHoliday && !state.publicHoliday) {
        state.publicHoliday = new PublicHoliday();
      }
    },
    modalControl(state, boolean) {
      state.modalBoolen = boolean;
    },
    getReservingDate(state, data) {
      state.reseringDate = { selected: data, today: new Date() };
    },
    getCalender(state, { type, data }) {
      if (type === "add") {
        state.calenderDetail.push(data);
      }
      if (type === "remove") {
        state.calenderDetail.splice(data, 1);
      }
    },
    setRangeHours(state, params) {
      const cd =
        params ||
        [...state.candidateRooms].find(
          (candidate) => candidate.selected && candidate.type === "drag"
        );
      if (cd) {
        const minutes =
          (cd.end.getHours() - cd.start.getHours()) * 60 +
          cd.end.getMinutes() -
          cd.start.getMinutes();
        state.rangeSearchHours = Math.floor(minutes / 60);
        state.rangeSearchMinutes = minutes % 60;
      } else {
        state.rangeSearchHours =
          state.options.find((op) => op.name === "所要時間")?.value ?? 1;
        state.rangeSearchMinutes =
          state.options.find((op) => op.name === "所要時間・分")?.value ?? 0;
      }
      clearRecommend();
    },
    updateRoomScr(state, updateRoomScr) {
      if (state.updateRoomScr !== updateRoomScr)
        state.updateRoomScr = updateRoomScr;
    },
    insertEvents(state, insert) {
      insert.rooms.forEach((room, index) => {
        Array.from(state.events)
          .find((eventRoom) => room.room_id === eventRoom.room_id)
          .list.push({
            id: insert.pRes.events[index].primary,
            owner: insert.owner,
            owner_color_code: insert.owner.color_code,
            schedule_id: insert.pRes.id,
            schedule_uuid: insert.pRes.uuid,
            status: insert.status,
            start_time: room.start_time,
            end_time: room.end_time,
            users: Array.from(room.member || []),
            color: room.color,
          });
      });
    },
    color(state, color) {
      state.color = color;
    },
    meetid(state, meetid) {
      state.meetid = meetid;
    },
    meetflg(state, meetflg) {
      state.meetflg = meetflg;
    },
    visibility(state, visibility) {
      state.visibility = visibility;
    },
    transparency(state, transparency) {
      state.transparency = transparency;
    },
    updateDefault(state, date) {
      state.defaultDate = date;
    },
    rooms(state, n) {
      if (state.rooms !== n.data) state.rooms = n.data;
      floor.updateFloors([...new Set(n.data.map((room) => room.floor))].sort());
    },
    staffs(state, n) {
      if (state.staffList !== n.data) state.staffList = n.data;
    },
    tags(state, n) {
      if (state.tags !== n.data) state.tags = n.data;
    },
    sendStartDays(state, n) {
      if (state.sendStartDays !== n.data) state.sendStartDays = n;
    },
    updateEvents(state, { events, date, days = 1 }) {
      const end = new Date(date);
      end.setDate(date.getDate() + 1);
      const time = date.getTime();
      const oneDay = 86400000;
      for (let i = 0; i < days; i++) {
        if (!state.requested.includes(time + i * oneDay)) {
          state.requested.push(time + i * oneDay);
        }
      }

      for (const event of events.data) {
        const searchedIndex = state.events.findIndex(
          (ev) => event.room_id === ev.room_id
        );
        if (searchedIndex === -1) {
          state.events.push(event);
          continue;
        }
        state.events[searchedIndex].list = state.events[
          searchedIndex
        ].list.filter(
          (ev) => !(date < new Date(ev.end) && end > new Date(ev.start))
        );
        state.events[searchedIndex].list.push(...event.list);
      }
      state.events = [...state.events];
    },
    setRangeSearchHours(state, range) {
      if (state.rangeSearchHours !== range) {
        state.rangeSearchHours = range;
        clearRecommend();
      }
    },
    setRangeSearchMinutes(state, range) {
      if (state.rangeSearchMinutes !== range) {
        state.rangeSearchMinutes = range;
        clearRecommend();
      }
    },
    addCandidateRoom(state, candidate) {
      for (let i = 0; i < state.candidateRooms.length; i++) {
        if (!state.candidateRooms[i] || !state.candidateRooms[i].selected) {
          Vue.set(state.candidateRooms, i, candidate);
          return;
        }
      }
      state.candidateRooms.length < 3 && state.candidateRooms.push(candidate);
    },
    updateCandidate(state, { candidate, index, target }) {
      if (target) {
        index = state.candidateRooms.indexOf(target);
      }
      Vue.set(state.candidateRooms, index, candidate);
    },
    removeCandidate(state, candidate) {
      const index = state.candidateRooms.indexOf(candidate);
      if (index !== -1) {
        Vue.set(state.candidateRooms[index], "selected", false);
      }
    },
    shiftFloors(state) {
      state.floor = floor.shift().name;
      clearRecommend();
    },
    email(state, email) {
      state.email = email;
    },
    addTagFilter(state, tagId) {
      state.selectedTags.push(tagId);
    },
    removeTagFilter(state, tagId) {
      state.selectedTags = state.selectedTags.filter((id) => id !== tagId);
    },
    toggleSelectedTag(state, tagId) {
      if (state.selectedTags.includes(tagId)) {
        state.selectedTags = state.selectedTags.filter((id) => id !== tagId);
      } else {
        state.selectedTags.push(tagId);
      }
      clearRecommend();
    },
    setReversedTag(state, bool) {
      state.reversedTag = bool;
      clearRecommend();
    },
    setMaxCapacity(state, capacity) {
      state.capacity = Number(capacity);
      clearRecommend();
    },
    updateRecommend(state, { recommend, index }) {
      Vue.set(state.candidateRooms, index, recommend);
    },
    removeRecommend(state, index) {
      const target = state.candidateRooms[index];
      state.removedRecommends.push(target);
      state.candidateRooms = state.candidateRooms.filter((cr) => cr !== target);
    },
    clearRecommends() {
      clearRecommend();
    },
    resetModal(state) {
      state.candidateRooms = state.candidateRooms.filter(
        (room) => room?.selected
      );
      state.selectedTags = [];
      state.capacity = state.options.find(
        (op) => op.name === "収容人数"
      )?.value;
      state.removedRecommends = [];
      state.floor = floor.clear().name;
      state.searchTimeStart = getInitSearchTime().value;
      state.searchTimeEnd = null;
      state.editSearchTime = false;
      state.searchDateStart = today();
      state.searchDateEnd = null;
      state.editSearchDate = false;
    },
    clearSearchTime(state) {
      state.searchTimeStart = getInitSearchTime().value;
      state.searchTimeEnd = null;
      state.editSearchTime = false;
    },
    removeSearchTime(state, [date1, date2]) {
      state.searchTimeStart = getInitSearchTime().value;
      state.searchTimeEnd = null;
      state.editSearchTime = false;
    },
  },
  getters: {
    isDefaultConditions: (state) => {
      return (
        state.rangeSearchHours ===
          state.options.find((op) => op.name === "所要時間")?.value &&
        state.rangeSearchMinutes ===
          state.options.find((op) => op.name === "所要時間・分")?.value &&
        state.capacity ===
          state.options.find((op) => op.name === "収容人数")?.value
      );
    },
    includeSearchRange: (state, getters) => ({ start, end }) => {
      const range = getters.searchRange;
      const rangeStart = start.getHours() * 60 + start.getMinutes();
      let rangeEnd = end.getHours() * 60 + end.getMinutes();
      if (
        end.getFullYear() !== start.getFullYear() ||
        end.getMonth() !== start.getMonth() ||
        end.getDate() !== start.getDate()
      ) {
        rangeEnd += 1440;
      }

      return range.start <= rangeStart && range.end >= rangeEnd;
    },
    searchRange: (state) => {
      const option = state.options.find((op) => op.name === "検索時間範囲");
      if (option) {
        const s = option.value.start.split(":").map(Number);
        const e = option.value.end.split(":").map(Number);
        return {
          start: s[0] * 60 + s[1],
          end: e[0] * 60 + e[1],
        };
      }
      return {
        start: 0,
        end: 1440,
      };
    },
    getRoomById: (state) => (id) => state.rooms.find((room) => id === room.id),
    getTagNameById: (state) => (id) =>
      state.tags.find((tag) => id === tag.id).name,
    getTagFilteredRooms: (state) => (rooms) => {
      return rooms.filter((room) => {
        if (state.openModal) {
          if (state.capacity !== 0 && state.capacity > room.capacity)
            return false;
          if (floor.value !== "All" && room.floor !== floor.value) return false;
        }
        if (state.selectedTags.length === 0) return true;
        if (state.reversedTag) {
          return !state.selectedTags.some((id) => room.tags.includes(id));
        } else {
          return state.selectedTags.some((id) => room.tags.includes(id));
        }
      });
    },
    getFilteredRooms: (state, getters) => (rooms) => {
      const check = rooms || state.rooms;
      const tagFiltered = getters.getTagFilteredRooms(Array.from(check));
      return tagFiltered.filter((room) => {
        if (!state.openModal) return true;
        const events = state.events.find((ev) => room.id === ev.room_id);
        if (events) {
          if (!state.requested.includes(state.defaultDate.getTime()))
            return false;
          const ss = new Date(state.defaultDate);
          const se = new Date(state.defaultDate);
          let enableRoom = false;
          for (let i = 0; i + state.rangeSearchHours < 24; i++) {
            const hasEvent = events.list.some((ev) => {
              const es = new Date(ev.start_time);
              const ee = new Date(ev.end_time);
              ss.setHours(i);
              se.setHours(i + state.rangeSearchHours);
              se.setMinutes(state.rangeSearchMinutes);
              return (
                (ee > ss && ee <= se) ||
                (es >= ss && es < se) ||
                (es < ss && ee > se)
              );
            });

            if (!hasEvent) {
              enableRoom = true;
              break;
            }
          }

          if (!enableRoom) return false;
        }
        return true;
      });
    },
    getFilteredCapacity: (state) => ({ rooms, index }) => {
      const minCapacity = state.rooms.map((n) => n.capacity);
      if (index < Math.min(...minCapacity)) return false;
      return state.rooms.filter((n, i) => {
        if (state.candidateRooms[0]) {
          if (state.candidateRooms[0].id === n.id) return true;
        }
        if (state.candidateRooms[1]) {
          if (state.candidateRooms[1].id === n.id) return true;
        }
        if (state.candidateRooms[2]) {
          if (state.candidateRooms[2].id === n.id) return true;
        }
        return n.capacity >= index;
      });
    },
    findCandidates: (state) => (roomId, start) => {
      const end = new Date(start);
      end.setDate(end.getDate() + 1);
      const max = new Date(end);
      max.setDate(max.getDate() + 1);
      return (
        state.candidateRooms.filter((r) => {
          return (
            r?.selected &&
            r.id === roomId &&
            ((r.start >= start && r.start < end) ||
              (r.end >= end && r.end < max))
          );
        }) || []
      );
    },
    findEvents: (state) => (roomId, start) => {
      const end = new Date(start);
      end.setDate(end.getDate() + 1);
      const roomEvents = state.events.find((r) => r.room_id === roomId) || {
        list: [],
      };
      return {
        room_id: roomEvents.room_id,
        list: roomEvents.list.filter(
          (ev) =>
            new Date(ev.start_time.replace(/-/g, "/")) >= start &&
            new Date(ev.end_time.replace(/-/g, "/")) <= end &&
            ev.status !== "キャンセル"
        ),
      };
    },
    findSearch: (state) => (start, end) => {
      if (!state.searchTimeStart || !state.searchTimeEnd) return false;
      const s = state.searchTimeStart.split(":").map(Number);
      const e = state.searchTimeEnd.split(":").map(Number);
      const eo = end.getHours() === 0 ? 24 : end.getHours();
      return (
        start.getHours() === s[0] &&
        start.getMinutes() === s[1] &&
        eo === e[0] &&
        end.getMinutes() === e[1]
      );
    },
    hasEvent: (state) => (roomId, start, end) => {
      const roomEvents = state.events.find((r) => r.room_id === roomId) || {
        list: [],
      };
      return roomEvents.list.some((room) => {
        if (room.status === "キャンセル") return false;
        const newDateSt = room.start_time.replace(/-/g, "/");
        const newDateEd = room.end_time.replace(/-/g, "/");
        const rs = new Date(newDateSt);
        const re = new Date(newDateEd);
        return start < re && end > rs;
      });
    },
    hasDragCandidate: (state) => {
      return state.candidateRooms.some((r) => r?.selected && r.type === "drag");
    },
    getRecommend: (state, getters) => () => {
      const holiday = (
        state.options.find((op) => op.name === "休日設定") || {
          value: { public: true },
        }
      ).value;

      if (holiday.public && (!state.publicHoliday || !state.publicHoliday.done))
        return null;

      const isHoliday = (start) => {
        if (holiday.basic.includes(start.getDay())) return true;
        return holiday.public && state.publicHoliday.isHoliday(start);
      };

      const removed = (id, start, end) => {
        return state.removedRecommends.some(
          (rr) =>
            rr?.id === id &&
            rr.start.getTime() === start.getTime() &&
            rr.end.getTime() === end.getTime()
        );
      };
      const hasRecommend = (id, start, end) => {
        return state.candidateRooms.some(
          (r) =>
            r?.id === id &&
            start.getTime() === r.start.getTime() &&
            end.getTime() === r.end.getTime()
        );
      };
      const isOld = (start) => new Date() > start;
      const isSameDate = (start, end) => {
        const sd = start.getDate();
        const se = end.getDate();
        if (sd === se) return true;
        const ns = new Date(start);
        return ns.getDate() === se && end.getMinutes() === 0;
      };
      const isNotEnable = (id, start, end) =>
        isHoliday(start) ||
        removed(id, start, end) ||
        getters.hasEvent(id, start, end) ||
        hasRecommend(id, start, end) ||
        isOld(start) ||
        !isSameDate(start, end);
      const isEnableGuests = (start, end) =>
        !state.guests.some((guest) => !guest.isEnable(start, end));
      const rangeMinutes =
        state.rangeSearchHours * 60 + state.rangeSearchMinutes;
      const searchRecommend = (
        searchRooms,
        searchDateStart,
        searchDateEnd,
        searchStartTime,
        searchEndTime
      ) => {
        for (const room of new Set(searchRooms)) {
          if (!state.events.find((event) => event.room_id === room.id))
            continue;
          const sds = new Date(searchDateStart);
          const sde = new Date(searchDateEnd);
          sde.setDate(sde.getDate() + 1);
          const sts = searchStartTime.split(":").map(Number);
          const ste = searchEndTime.split(":").map(Number);
          // eslint-disable-next-line no-unmodified-loop-condition
          while (sds < sde) {
            const sdss = new Date(sds);
            const sdse = new Date(sds);
            sdss.setHours(sts[0]);
            sdss.setMinutes(sts[1]);
            sdse.setHours(ste[0]);
            sdse.setMinutes(ste[1]);
            // eslint-disable-next-line no-unmodified-loop-condition
            while (sdss < sdse) {
              const checkEnd = new Date(sdss);
              checkEnd.setMinutes(checkEnd.getMinutes() + rangeMinutes);
              if (checkEnd > sdse) break;
              if (
                !isNotEnable(room.id, sdss, checkEnd) &&
                isEnableGuests(sdss, checkEnd)
              ) {
                return {
                  id: room.id,
                  start: new Date(sdss),
                  end: new Date(checkEnd),
                  selected: false,
                };
              }
              sdss.setMinutes(sdss.getMinutes() + 15);
            }
            sds.setDate(sds.getDate() + 1);
          }
        }

        const sts = searchStartTime.split(":").map(Number);

        // 15 * (4 * 24h)
        for (let i = 0; i < 96; i++) {
          sts[1] += 15;

          for (const room of new Set(searchRooms)) {
            if (!state.events.find((event) => event.room_id === room.id))
              continue;
            const sds = new Date(searchDateStart);
            const sde = new Date(searchDateEnd);
            // eslint-disable-next-line no-unmodified-loop-condition
            while (sds < sde) {
              const sdss = new Date(sds);
              sdss.setHours(sts[0]);
              sdss.setMinutes(sts[1]);
              const sdse = new Date(sdss);
              sdse.setHours(24);
              sdse.setMinutes(0);
              // eslint-disable-next-line no-unmodified-loop-condition
              while (sdss < sdse) {
                const checkEnd = new Date(sdss);
                checkEnd.setMinutes(checkEnd.getMinutes() + rangeMinutes);
                if (checkEnd > sdse) break;
                if (
                  !isNotEnable(room.id, sdss, checkEnd) &&
                  isEnableGuests(sdss, checkEnd)
                ) {
                  return {
                    id: room.id,
                    start: new Date(sdss),
                    end: new Date(checkEnd),
                    selected: false,
                  };
                }
                sdss.setMinutes(sdss.getMinutes() + 15);
              }
              sds.setDate(sds.getDate() + 1);
            }
          }
        }
        return null;
      };

      const searchRooms = arrayShuffle(getters.getFilteredRooms());

      const recommend = searchRecommend(
        searchRooms,
        state.searchDateStart || today(),
        state.searchDateEnd || [...state.requested].sort().pop(),
        state.searchTimeStart || "0:0",
        state.searchTimeEnd || "24:0"
      );

      if (recommend) return recommend;

      return searchRecommend(
        searchRooms,
        state.searchDateStart || today(),
        [...state.requested].sort().pop(),
        state.searchTimeStart || "0:0",
        "24:0"
      );
    },
    addCandidateRoom: (state) => (candidate) => {
      for (let i = 0; i < state.candidateRooms.length; i++) {
        if (!state.candidateRooms[i] || !state.candidateRooms[i].selected) {
          Vue.set(state.candidateRooms, i, candidate);
          return state.candidateRooms[i];
        }
      }
      state.candidateRooms.length < 3 && state.candidateRooms.push(candidate);
      return state.candidateRooms[state.candidateRooms.length - 1];
    },
    addCandidateSearch: (state, getters) => (candidate) => {
      const value = {
        candidate: getters.addCandidateRoom(candidate),
        searchIndex: null,
      };
      value.candidate.type = "drag";
      return value;
    },
    candidate: (state, getters) => {
      state.candidateRooms = state.candidateRooms.filter(Boolean);
      while (state.candidateRooms.length < 3) {
        const r = getters.getRecommend();
        if (!r) break;
        state.candidateRooms[state.candidateRooms.length] = r;
      }

      const recommends = state.candidateRooms.filter(
        (candidate) => !candidate.selected
      );

      if (recommends.length === 0) {
        store.state.isOverSearchDate = false;
        store.state.isOverSearchTime = false;
      } else {
        store.state.isOverSearchDate = recommends.some(({ start, end }) => {
          if (!store.state.searchDateEnd) return false;
          const ds = store.state.searchDateStart || today();
          const de = new Date(store.state.searchDateEnd);
          de.setDate(de.getDate() + 1);
          return !(start >= ds && start < de && end > start && end <= de);
        });

        store.state.isOverSearchTime = recommends.some(({ start, end }) => {
          if (!state.searchTimeStart) return false;
          const shm = start.getHours() * 60 + start.getMinutes();
          const eh = start.getDate() === end.getDate() ? end.getHours() : 24;
          const ehm = eh * 60 + end.getMinutes();
          const ts = (state.searchTimeStart || "0:0").split(":").map(Number);
          const tsm = ts[0] * 60 + ts[1];
          const te = (state.searchTimeEnd || "24:0").split(":").map(Number);
          const tem = te[0] * 60 + te[1];
          return !(shm >= tsm && shm < tem && ehm > tsm && ehm <= tem);
        });
      }

      return state.candidateRooms;
    },
    selectedCandidates: (state) => {
      return state.candidateRooms.filter((room) => room?.selected);
    },
    isEmptyCandidate: (state) => {
      if (state.candidateRooms.length < 3) return true;
      return state.candidateRooms.some((candidate) => {
        return !candidate || !candidate.selected;
      });
    },
    findOptions: (state) => (name) =>
      Array.from(state.options).find((op) => op.name === name),
    spCheckIn: (state) => {
      const f = state.options.find(
        (op) => op.name === "スマートフォンからのチェックイン"
      );
      return f ? f.value : false;
    },
    spCheckOut: (state) => {
      const f = state.options.find(
        (op) => op.name === "スマートフォンからのチェックアウト"
      );
      return f ? f.value : false;
    },
    cancelCheckTime: (state) => {
      const f = state.options.find(
        (op) => op.name === "未チェックイン時の自動キャンセル期間"
      );
      return f ? f.value : false;
    },
  },
  actions: {
    loadEvents(
      { commit },
      { date, days = 1, ...params } = { date: Date.now(), days: 1, params: {} }
    ) {
      const d = new Date(date);
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      const sd = parseDate(d);
      const d2 = new Date(d);
      d2.setDate(d2.getDate() + days);
      const ed = parseDate(d2);
      const query = {
        ...params,
        start_time: `${sd.year}-${sd.month}-${sd.date} 00:00:00`,
        end_time: `${ed.year}-${ed.month}-${ed.date} 00:00:00`,
      };
      store.state.searchDateStartTmp = new Date(query.start_time);
      store.state.daysPush = days;
      getSchedules(query).then(
        (res) => {
          this.state.events = [];
          commit("updateEvents", { events: res, date: d, days });
          commit("updateLoginError", "succeed");
        },
        (e) => console.warn(e)
      );
    },
    statusTimer({ commit, getters }, { status }) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const nEvStandBy = new Date(status.replace(/-/g, "/"));
        const mins =
          nEvStandBy.getMinutes() -
          getters.findOptions("チェックイン可能時間").value;
        nEvStandBy.setMinutes(mins);
        if (now < new Date(nEvStandBy).getTime()) {
          this.state.standByEventCheckIn = false;
          clearInterval(timer);
        }
        if (now > new Date(nEvStandBy).getTime()) {
          this.state.standByEventCheckIn = true;
          clearInterval(timer);
        }
      }, 5000);
    },
  },
});

const watchDate = async () => {
  if (!store.state.searchDateStart && !store.state.searchDateEnd) return;
  const oneDay = 86400000;
  const end =
    store.state.searchDateEnd ||
    store.state.searchDateStart.getTime() + oneDay * 14;
  const start = store.state.searchDateStart || today();
  const days = (Number(end) - Number(start)) / oneDay + 14;
  if (days > 0)
    return store
      .dispatch("loadEvents", { date: start, days })
      .then(clearRecommend);
};

store.watch(
  (s) => s.searchDateStart,
  (value, oldValue) => {
    watchDate().then(() => {
      const isUpdateDefaultDate =
        store.state.calendarMode === "search" &&
        (!oldValue || oldValue.getTime() === store.state.defaultDate.getTime());
      if (isUpdateDefaultDate) store.state.defaultDate = value;
    });
  }
);
store.watch((s) => s.searchDateEnd, watchDate);
store.watch((s) => s.searchTimeStart, clearRecommend);
store.watch((s) => s.searchTimeEnd, clearRecommend);

if (process.env.NODE_ENV !== "production") {
  console.log(store.state);
}
export default store;
