import Img from "../assets/logo__img.png";

export default {
  name: "TheModalSignOut",
  methods: {
    signOut() {
      gapi.auth2
        .getAuthInstance()
        .signOut()
        .then(() => {
          localStorage.removeItem("mySchedule-hash");
          localStorage.removeItem("mySchedule");
          this.$store.state.loginStatus = false;
          this.$router
            .push({ name: "login" })
            .then(() => location.reload())
            .catch(() => {});
          this.$modal.hide("TheModalSignOut");
        });
    },
  },
  render() {
    const email = this.$store.state.email;
    return (
      <modal
        name="TheModalSignOut"
        class="sideMenu"
        pivotY={0}
        pivotX={0}
        height="100%"
        width="85%"
      >
        <div>
          <nav class="c-nav__wrap">
            <p class="c-nav_ttl">
              <img src={Img} alt="logo" />
              <span class="app-version">{APP_VERSION}</span>
            </p>
            <ul class="c-nav-list c-nav__account-list">
              <li class="is-in">
                <p class="c-nav__account-color u-color01" />
                <span class="c-nav__mail">{email}</span>
              </li>
            </ul>
            <ul class="c-nav-list c-nav__sign-list is-in">
              <li onClick={this.signOut}>
                <div class="c-nav__img">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </div>
                <span class="c-nav__sign-ttl">サインアウト</span>
              </li>
            </ul>
          </nav>
        </div>
      </modal>
    );
  },
};
