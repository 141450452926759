export default {
  name: "TheLoading",
  render() {
    return (
      <div
        class={["connection-loading", { show: this.$store.state.isLoading }]}
      >
        <div />
      </div>
    );
  },
};
