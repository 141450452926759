export default {
  name: "TheSideMenuOpenButton",
  render() {
    return (
      <div class="js-side-menu_wrap">
        <div class="c-side-menu">
          <div id="c-btn-spmenu" onClick={this.$listeners.click.fns}>
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    );
  },
};
