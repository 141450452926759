import { dragscroll } from "vue-dragscroll";
import CalendarOneTimeScale from "./CalendarOneTimeScale";

export default {
  name: "TheCalendarTimeScales",
  directives: {
    dragscroll: dragscroll,
  },
  mounted() {
    this.$store.watch(
      (s) => s.options,
      () => {
        this.$forceUpdate();
      }
    );
  },
  render() {
    const times = [];
    for (let hour = 1; hour < 24; hour++) {
      times.push(
        <CalendarOneTimeScale />,
        <CalendarOneTimeScale hour={hour} />
      );
    }
    return (
      <div class="time_wrap" style="background: #01A69B">
        <div
          ref="times"
          style={{
            willChange: "scroll-position",
            "-webkit-overflow-scrolling": "touch",
          }}
          data-name="time"
          class="js_vue_drag_scroll_wrap c-calender__vue_drag"
          v-dragscroll
          onDragscrollmove={this.$listeners.scroll}
          onScroll={this.$listeners.scroll}
        >
          <div class="range" ref="range">
            <table
              ref="time"
              class="c-calendar__table-sub js_vue_drag_scroll_child"
            >
              <tbody>
                <tr>
                  <CalendarOneTimeScale />
                  {times}
                  <CalendarOneTimeScale />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },
};
