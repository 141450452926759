import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import today from "utils/today";
import times from "data/times";
import getInitSearchTime from "functions/time/getInitSearchTime";
import TimeSelect from "components/TimeSelect";
import Img from "assets/calendar-icon.png";

export default {
  name: "TheSearchByTimesSelectList",
  methods: {
    clearDate() {
      this.$store.state.searchDateStart = today();
      this.$store.state.searchDateEnd = null;
      this.$store.state.editSearchDate = false;
    },
    clearHours() {
      this.$store.state.searchTimeStart = getInitSearchTime().value;
      this.$store.state.searchTimeEnd = null;
      this.$store.state.editSearchTime = false;
    },
    handleInputCalendar(isStart) {
      return (date) => {
        const td = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        if (isStart) {
          this.$store.state.searchDateStart = td;
          if (td > this.$store.state.searchDateEnd) {
            const startTime = td.getTime();
            const diff = startTime - this.$store.state.searchDateEnd.getTime();
            this.$store.state.searchDateEnd = new Date(startTime + diff);
          }
        } else {
          this.$store.state.searchDateEnd = td;
        }
        this.$store.state.editSearchDate = true;
      };
    },
    handleInputSearchTimeStart(selected) {
      const startIndex = times.findIndex(
        ({ label }) => label === selected.label
      );
      const endIndex = times.findIndex(
        ({ label }) => label === this.$store.state.searchTimeEnd
      );
      if (startIndex === endIndex) {
        this.$store.state.searchTimeEnd = times[endIndex + 1].label;
      } else if (startIndex > endIndex) {
        this.$store.state.searchTimeEnd = (
          times[startIndex + startIndex - endIndex] || times[times.length - 1]
        ).label;
      }
      this.$store.state.searchTimeStart = selected.label;
    },
    todayDate() {
      const base = this.$store.state.searchDateStart || today();
      return new Date(base);
    },
  },
  render() {
    return (
      <div class="c-search-new__box-area">
        <div class="c-search-new__box">
          <p class="c-search-new-ttl">・検索条件1：日付</p>
          <div class="c-search-new-select__box-wrap c-search-new-date">
            <div class="c-search-new-select__box">
              <Datepicker
                ref="picker1"
                class="c-search-new-select__date"
                language={ja}
                value={this.$store.state.searchDateStart}
                format="yyyy年MM月dd日"
                placeholder="----年--月--日"
                onInput={this.handleInputCalendar(true)}
                inline={false}
              />
              <span
                class="c-search-new-select__date-icon"
                onClick={() => this.$refs.picker1.showCalendar()}
              >
                <img src={Img} alt="カレンダー" />
              </span>
            </div>
            <span class="c-search-new-select-while">-</span>
            <div
              class={[
                "c-search-new-select__box",
                { "is-error": this.$store.state.isOverSearchDate },
              ]}
            >
              <Datepicker
                ref="picker2"
                class="c-search-new-select__date select__max_date"
                language={ja}
                value={this.$store.state.searchDateEnd}
                format="yyyy年MM月dd日"
                placeholder="----年--月--日"
                onInput={this.handleInputCalendar(false)}
                inline={false}
                disabledDates={{
                  to: this.todayDate(),
                }}
              />
              <span
                class="c-search-new-select__date-icon"
                onClick={() => this.$refs.picker2.showCalendar()}
              >
                <img src={Img} alt="カレンダー" />
              </span>
            </div>
            <div class="c-search-new-cancel__btn-wrap" onClick={this.clearDate}>
              <p class="c-search-new-cancel__btn">クリア</p>
            </div>
          </div>
        </div>
        <div class="c-search-new__box">
          <p class="c-search-new-ttl">・検索条件2：時間</p>
          <div class="c-search-new-select__box-wrap c-search-new-time">
            <div class="c-search-new-select__box">
              <TimeSelect
                style="width:100%;"
                ref="s1"
                class="c-search-new-time"
                value={this.$store.state.searchTimeStart || ""}
                placeholder="--:--"
                onInput={this.handleInputSearchTimeStart}
                filter={(selectTimes) =>
                  selectTimes.slice(0, selectTimes.length - 1)
                }
              />
            </div>
            <span class="c-search-new-select-while">-</span>
            <div
              class={[
                "c-search-new-select__box",
                { "is-error": this.$store.state.isOverSearchTime },
              ]}
            >
              <TimeSelect
                style="width:100%;"
                ref="s2"
                class="c-search-new-time"
                value={this.$store.state.searchTimeEnd || ""}
                placeholder="--:--"
                onInput={(selected) =>
                  (this.$store.state.searchTimeEnd = selected.label)
                }
                filter={(selectTimes) => {
                  const startIndex = selectTimes.findIndex(
                    ({ label }) => label === this.$store.state.searchTimeStart
                  );
                  return selectTimes.filter(
                    (time, index) => index > startIndex
                  );
                }}
              />
            </div>
            <div
              class="c-search-new-cancel__btn-wrap"
              onClick={this.clearHours}
            >
              <p class="c-search-new-cancel__btn">クリア</p>
            </div>
          </div>
        </div>
        {(this.$store.state.isOverSearchDate ||
          this.$store.state.isOverSearchTime) && (
          <div class="c-search-new__error">
            <p>所要時間と検索範囲の時間が一致しいていません</p>
          </div>
        )}
      </div>
    );
  },
};
