export default {
  name: "CardCommonEventTitle",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  render() {
    const thisEv = this.event;
    const tag = this.$store.state.tags;
    const targetRoom = this.$store.state.rooms.filter(
      (_this) => _this.id === Number(thisEv.room_id)
    );
    const tagArr = [];
    targetRoom.forEach((room, i) => {
      room.tags.forEach((_this) => {
        tag.forEach((tag) => {
          if (tag.id === Number(_this)) tagArr.push(tag);
        });
      });
    });
    return (
      <div class="c-myreserve-info">
        <p class="c-select-info__tooltips c-label-tooltips-item">
          <span class="c-label-tooltips">{targetRoom[0].name}</span>
          <p
            class="c-myreserve-info__room"
            onClick={(e) =>
              e.target.offsetWidth !== e.target.scrollWidth &&
              e.target.parentNode.classList.toggle("is-active")
            }
          >
            {targetRoom[0].name}
          </p>
        </p>
        {tagArr.map((tag) => {
          return <p class="c-myreserve-info__name">{tag.name}</p>;
        })}
        <p class="c-myreserve-info__people">
          <span class="c-myreserve-info__people-number">
            {targetRoom[0].capacity}
          </span>
          人
        </p>
      </div>
    );
  },
};
