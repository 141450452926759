import TheColorPicker from "components/picker/ColorPicker/TheColorPicker";

export default {
  name: "TheReservationTitle",
  methods: {
    hide() {
      this.$modal.hide("TheModalReservation");
      this.$store.commit("resetModal");
    },
    handleChange(color) {
      this.$store.commit("color", { id: color.colorId, code: color.color });
    },
  },
  render() {
    return (
      <div>
        <div class="p-modal-ttl__wrap">
          <div slot="top-right">
            <button class="c-modal-cancel__btn" onClick={this.hide} />
          </div>
          <div class="c-modal-main__ttl">
            <input
              type="text"
              class="c-modal-ttl__input"
              placeholder="タイトルを入力"
            />
          </div>
        </div>
        <div class="c-plan__wrap">
          <div class="c-plan__output">
            <p class="c-plan__mail">{this.$store.state.email}</p>
            <TheColorPicker onChange={this.handleChange} />
          </div>
        </div>
      </div>
    );
  },
};
