import ParseDate from "utils/parseDate";
import { putSchedules } from "network/server";

export default {
  name: "TheModalMyReserveCheck",
  data() {
    return {
      initial: true,
    };
  },
  methods: {
    beforeOpen() {
      if (!this.initial) {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
      this.initial = false;
    },
    hide() {
      this.$modal.hide("TheModalMyReserveCheck");
    },
  },
  render() {
    const modalType = this.$store.state.modalForBtn;
    const checkInList = this.$store.state.storeReservationCancel;
    const events = [];

    if (!checkInList || modalType === "") {
      return;
    }

    if (modalType === "tentativeCancel") {
      checkInList.mySch.schedule.events.forEach((event) => {
        events.push({
          id: event.room_id,
          eventId: event.id,
          name: event.location,
          start: event.start_time,
          end: event.end_time,
          users: checkInList.googleCal.guest,
          calendar_id: event.calendar_id,
          room: this.$store.state.rooms.find(
            (n) => n.id === Number(event.room_id)
          ),
        });
      });
    } else {
      events.push({
        id: checkInList.room_id,
        eventId: checkInList.eventId,
        name: checkInList.location,
        start: checkInList.start_time,
        end: checkInList.end_time,
        users: checkInList.googleCal.guest,
        calendar_id: checkInList.calendar_id,
        room: this.$store.state.rooms.find(
          (n) => n.id === Number(checkInList.room_id)
        ),
      });
    }

    const checkIn = async () => {
      this.$store.state.isLoading = true;
      const status =
        {
          checkOut: "完了",
          cancel: "キャンセル",
          tentativeCancel: "キャンセル",
        }[modalType] || "使用中";
      await putSchedules({
        schedule_uuid: checkInList.mySch.uuid,
        rooms: events.map((event) => ({
          event_id: event.eventId,
          update: { status },
        })),
      })
        .then((r) => (r.success ? r : Promise.reject(r)))
        .then(async () => {
          switch (modalType) {
            case "cancel":
              this.$store.commit("updateCalenderscedules", {
                type: "reserveCancel",
                data: events[0].calendar_id,
              });
              break;
            case "tentativeCancel":
              this.$store.commit("updateCalenderscedules", {
                type: "cancelAllTentative",
                data: [events[0].calendar_id],
              });
              break;
            case "standBy":
              this.$store.state.standByEventCheckIn = false;
              this.$store.commit("updateCalenderscedules", {
                type: "setInUsed",
                data: events[0].calendar_id,
              });
              break;
            case "checkOut":
              this.$store.state.getMySchedulesReserveInUsed = "";
              this.$store.state.tentativeUpdateInUsedId = events[0].eventId;
              break;
          }
          const planTxtNone = this.$store.state.storeReservationEvents.find(
            (status) => status.status === "予約"
          );
          if (!planTxtNone) this.$store.state.planTxtNone = true;
          this.$store.dispatch("loadEvents");
          this.$modal.hide("TheModalMyReserveCheck");
        })
        .catch((e) => {
          console.warn(e);
          alert("チェックイン待ちの更新に通信エラーが発生しました。");
        });

      this.$store.state.isLoading = false;
    };
    const time = (time) => {
      const { hours, minutes } = ParseDate(new Date(time.replace(/-/g, "/")));
      return [hours, minutes];
    };

    const type = {
      checkOut: {
        text: ["チェックアウト", "下記の予約をチェックアウトしますか？"],
        class: "c-myreserve-modal-btn__wrap is-inuse",
      },
      standBy: {
        text: ["チェックイン", "下記の予約にチェックインしますか？"],
        class: "c-myreserve-modal-btn__wrap is-standby",
      },
      cancel: {
        text: ["予約をキャンセル", "下記の予約をキャンセルしますか"],
        class: "c-myreserve-modal-btn__wrap is-inuse",
      },
      tentativeCancel: {
        text: ["仮予約をキャンセル", "下記の仮予約をキャンセルしますか"],
        class: "c-myreserve-modal-btn__wrap is-tentative",
      },
    }[modalType];

    const resources = events.map((ev) => ev.room.resource_id);

    return (
      <modal
        name="TheModalMyReserveCheck"
        pivotY={0.5}
        height="auto"
        width="90%"
        onBefore-open={this.beforeOpen}
      >
        <div class="p-myreserve-modal">
          <div slot="top-right">
            <button class="c-modal-cancel__btn" onClick={this.hide} />
          </div>
          <div class="c-myreserve-modal__content">
            <p class="c-myreserve-modal__ttl">{type.text[1]}</p>
            {events.map((event) => (
              <div class="c-myreserve-modal__list-box u-check__box">
                <div class="c-myreserve-modal__list-wrap  u-check__list">
                  <p class="c-myreserve-modal-room__name">
                    <span class="c-myreserve-modal-room__sub-name">
                      {event.room.floor}
                    </span>
                    {event.room.name}
                  </p>
                </div>
                <div class="c-myreserve-modal__list-wrap">
                  <div class="c-myreserve-modal-meeting__time">
                    <p class="c-myreserve-modal-meeting__time-in">
                      {time(event.start)[0]}:{time(event.start)[1]}
                    </p>
                    <span class="c-myreserve-modal-meeting__time-while">-</span>
                    <p class="c-myreserve-modal-meeting__time-in">
                      {time(event.end)[0]}:{time(event.end)[1]}
                    </p>
                  </div>
                  <p class="c-myreserve-modal-meeting__ttl">打ち合せ</p>
                  {event.users
                    .filter((user) => !resources.includes(user.email))
                    .map((n) => (
                      <p class="c-myreserve-modal-meeting__name">{n.email}</p>
                    ))}
                </div>
              </div>
            ))}
            <div class={type.class}>
              <p
                class={[
                  "c-btn c-myreserve-modal-btn",
                  { "standBy-cancelBtn": modalType === "cancel" },
                ]}
                onClick={() => checkIn(modalType)}
              >
                {type.text[0]}
              </p>
            </div>
          </div>
        </div>
      </modal>
    );
  },
};
