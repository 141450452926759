import { postSchedules, postMeet } from "network/server";
import { insertGcalender } from "network/google";
import { registration } from "functions/reserveJs/getPostData";
import TheReservationTitle from "./TheReservationTitle";
import TheCandidateRooms from "./candidate/TheCandidateRooms";
import TheSearchByGuests from "./search/TheSearchByGuests";
import TheSearchByConditions from "./search/TheSearchByConditions";
import TheSearchByTimes from "./search/TheSearchByTimes";
import calendarEvents from "../../../states/calendarEvents";

const getScheduleInfo = () => {
  return {
    summary:
      document.querySelector(".c-modal-ttl__input").value || "会議室予約",
    description: document.querySelector(".c-plan-textarea").value || "",
  };
};

export default {
  name: "TheModalReservation",
  methods: {
    beforeClose() {
      this.$store.commit("clearCandidate");
    },
    hide() {
      if (this.$store.state.modalBoolen) {
        this.$store.commit("modalControl", false);
        return;
      }
      this.$modal.hide("TheModalColorPickerSelectList");
    },
    getSchedulePeriod(rooms) {
      const date2100 = new Date(Date.UTC(2100, 0));
      const date1970 = new Date(0);
      const start = rooms
        .map((room) => new Date(room.start_time.replaceAll("-", "/")))
        .reduce((a, b) => {
          return a < b ? a : b;
        }, date2100);

      const end = rooms
        .map((room) => new Date(room.end_time.replaceAll("-", "/")))
        .reduce((a, b) => {
          return a > b ? a : b;
        }, date1970);

      return {
        start,
        end,
        days: Math.ceil(Math.abs(start - end) / 86400000),
      };
    },
  },
  render() {
    const handleReserveClick = async (e) => {
      this.$store.state.isLoading = true;
      this.$store.state.reserveModalState = true;
      const status = e.target.value;
      const { color, email } = this.$store.state;
      const owner = calendarEvents.getNew(email, color.code ?? "#d50001");
      const { summary, description } = getScheduleInfo();
      const guests = [...this.$store.state.guests].map((ce) => ({
        email: ce.calendarId,
        color_code: ce.color.color,
      }));
      e.target.disabled = true;
      this.$store.state.completeTxt = status;
      const meetflg = this.$store.state.meetflg;
      const vflg = this.$store.state.visibility;
      const transparency = this.$store.state.transparency;

      try {
        const postData = registration(owner, {
          status,
          guests,
          summary,
          description,
          meetflg,
          vflg,
          transparency,
        });
        const pRes = await postSchedules(postData);
        const isReserve = status === "予約";
        const prefix = (
          this.$store.getters.findOptions("仮予約prefix") || { value: "" }
        ).value;

        const promises = Promise.all(
          postData.rooms.map((postRoom, index) => {
            const { id, start, end } = this.$store.getters.selectedCandidates[
              index
            ];
            const room = this.$store.state.rooms.find((room) => room.id === id);
            return insertGcalender(
              email,
              [room.resource_id, ...guests.map((guest) => guest.email)],
              {
                id: pRes.events[index].primary,
                start,
                end,
                colorId: color.id,
                summary: isReserve ? summary : `${prefix}${summary}`,
                description,
                location: room.name,
                meetflg: pRes.meetflg,
                vflg: pRes.vflg,
                transparency: pRes.transparency,
              }
            );
          })
        );

        await promises;
        promises.then((result) => {
          return result.map((res, index) => {
            if ("conferenceData" in res.result) {
              const { id, start, end } = this.$store.getters.selectedCandidates[
                index
              ];
              const meetid = res.result.conferenceData.conferenceId;
              const roomid = id;
              const prm1 = registration(owner, {
                meetid,
                roomid,
                start,
                end,
                status,
                summary,
                description,
              });
              return postMeet(prm1);
            }
            return "p";
          });
        });

        this.$store.commit("insertEvents", { ...postData, pRes });
        const period = this.getSchedulePeriod(postData.rooms);
        await this.$store.dispatch("loadEvents", {
          date: period.start,
          days: period.days,
        });

        this.$modal.hide("TheModalReservation");
        this.$modal.show("TheModalCompleteReserve");
      } catch (e) {
        console.warn(e);
        if (e?.message?.substr(0, 30) === "Not available during that time") {
          alert("指定した時間はすでに会議室が予約されています。");
        } else {
          alert("予約処理が正常に完了しませんでした。");
        }
        this.$el
          .querySelectorAll(".js_disabled_reserve_btn")
          .forEach((n) => (n.disabled = false));
      }
      this.$store.state.isLoading = false;
    };
    const activeLabel = (e) => {
      const guest = this.$store.state.guests.length;
      const hasSearch = !!(
        this.$store.state.searchDateStart ||
        this.$store.state.searchDateEnd ||
        this.$store.state.searchTimeStart ||
        this.$store.state.searchTimeEnd
      );
      const conditions = {
        hours: this.$store.state.rangeSearchHours,
        capacity: this.$store.state.capacity,
        tags: this.$store.state.selectedTags.length,
      };
      const classList = e.target.classList;
      const classToggle = (boolean) => {
        if (boolean) {
          if (!classList.contains("is-listed")) classList.toggle("is-active");
          classList.remove("is-listed");
        } else {
          if (classList.contains("is-active")) classList.toggle("is-active");
          classList.add("is-listed");
        }
      };
      if (e.target.id === "labelGuest") classToggle(!guest);
      if (e.target.id === "labelSearchParams") classToggle(!hasSearch);
      if (e.target.id === "labelConditions")
        classToggle(
          conditions.hours <= 1 && conditions.capacity <= 0 && !conditions.tags
        );
    };
    return (
      <modal
        name="TheModalReservation"
        class="p-reserv-modal testtets"
        style="height:100vh;"
        height="100%"
        pivotX={0}
        width="100%"
        onBefore-open={(e) => {
          this.$store.commit("setRangeHours", e.params);
          this.$store.commit("openModal", true);
        }}
        onBefore-close={() => {
          this.$store.commit("resetModal");
          if (!this.$store.state.reserveModalState)
            this.$store.state.candidateRooms = [];
          this.$store.state.guests = [];
          this.$store.state.color = { id: 11, code: "#d50001" };
          this.$store.commit("openModal", false);
        }}
      >
        <div id="js_reservation_wrap" onClick={this.hide}>
          <TheReservationTitle />
          <div class="p-reserve__main-wrap">
            <TheCandidateRooms onClick={handleReserveClick} />
          </div>
          <div class="p-reserve__sub-wrap">
            <TheSearchByGuests onClick={activeLabel} />
            <TheSearchByConditions onClick={activeLabel} />
            <TheSearchByTimes onClick={activeLabel} />
          </div>
        </div>
      </modal>
    );
  },
};
