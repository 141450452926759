export default {
  name: "CalendarOneTimeScale",
  props: {
    hour: {
      type: Number,
      default: -1,
    },
  },
  render() {
    return (
      <td>
        <p class="c-calendar__table-sub__time">
          {this.hour > -1 ? `${this.hour}:00` : ""}
        </p>
      </td>
    );
  },
};
