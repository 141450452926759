import times from "data/times";
import { createTimeValueString } from "./createTime";

export default function (value) {
  const time =
    value && typeof value !== "string" ? createTimeValueString(value) : value;
  return (
    times.find((n) => time === n.value || time === n.label) || { value: "" }
  );
}
