import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import parseDate from "utils/parseDate";
import { createTimeLabelString } from "functions/time/createTime";
import getDetailTime from "functions/time/getDetailTime";
import TimeSelect from "components/TimeSelect";

export default {
  name: "CardCommonDetailTime",
  props: {
    event: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vueSelectSt: new Date(this.event.ev.start_time.replace(/-/g, "/")),
      vueSelectEd: new Date(this.event.ev.end_time.replace(/-/g, "/")),
      timeFirst: this.$store.state.editInUsedMode,
    };
  },
  methods: {
    handleInputStart(selected) {
      const start = new Date(this.vueSelectSt);
      const [hour, min] = selected.value.split(":").map(Number);
      start.setHours(hour);
      start.setMinutes(min);
      this.vueSelectSt = start;
    },
    handleInputEnd(selected) {
      const end = new Date(this.vueSelectSt);
      const [hour, min] = selected.value.split(":").map(Number);
      end.setHours(hour);
      end.setMinutes(min);
      this.vueSelectEd = end;
    },
  },
  render() {
    const { startTime, reStart, reEnd } = getDetailTime(this.event.ev);
    const { year, month, date } = parseDate(new Date(startTime));
    const checkInTime = `${year}年${month}月${date}日`;
    if (this.editable && this.$store.state.editInUsedMode) {
      const makeStart = reStart.label;
      const makeEnd = reEnd.label;
      this.vueSelectSt = new Date(`${year}/${month}/${date} ${makeStart}`);
      this.vueSelectEd = new Date(`${year}/${month}/${date} ${makeEnd}`);
      this.$store.state.editInUsedMode = false;
    }
    const startTimeLabel =
      reStart?.label ?? createTimeLabelString(new Date(startTime));

    return (
      <div class="c-myreserve-daytime">
        {!this.editable && (
          <p class="c-myreserve-daytime__date">{checkInTime}</p>
        )}
        {this.editable && (
          <div class="c-select-daytime__date-label">
            <Datepicker
              ref="picker"
              class="c-select-daytime__date myreserveDatePicker"
              style={
                this.event.ev.status === "使用中" && "pointer-events: none;"
              }
              language={ja}
              value={startTime}
              format="yyyy年MM月dd日"
              // onInput={onInput}
            />
          </div>
        )}
        {!this.editable && (
          <div class="c-myreserve-daytime__time-wrap">
            <p class="c-myreserve-daytime__time-in">{startTimeLabel}</p>
            <span class="c-myreserve-daytime__time-while">-</span>
            <p class="c-myreserve-daytime__time-out">{reEnd.label}</p>
          </div>
        )}
        {this.editable && (
          <div class="c-myreserve-daytime__time-wrap myreserve-daytime-select">
            {this.type === "使用中" && (
              <p
                class="c-myreserve-daytime__time-in"
                data-select-val={this.vueSelectSt}
              >
                {reStart.label}
              </p>
            )}
            {this.type !== "使用中" && (
              <TimeSelect
                class="c-myreserve-daytime__time-in"
                style={
                  this.event.ev.status === "使用中" && "pointer-events: none;"
                }
                data-select-val={this.vueSelectSt}
                value={this.vueSelectSt}
                onInput={this.handleInputStart}
              />
            )}
            <span class="c-select-daytime__time-while">-</span>
            <TimeSelect
              class="c-myreserve-daytime__time-out"
              data-select-val={this.vueSelectEd}
              value={this.vueSelectEd}
              start={this.vueSelectSt}
              onInput={this.handleInputEnd}
            />
          </div>
        )}
      </div>
    );
  },
};
