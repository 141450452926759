import CalendarEvent from "classes/CalendarEvent";
import colors from "data/colors";
import store from "store";

export default {
  calendarEvents: {},
  get(calendarId, name = null) {
    if (!name) {
      const staff = store.state.staffList.find(
        (staff) => staff.email === calendarId
      );
      if (staff) name = staff.name;
    }

    if (!this.calendarEvents[calendarId]) {
      const color =
        colors[Object.keys(this.calendarEvents).length % colors.length];
      this.calendarEvents[calendarId] = new CalendarEvent(
        calendarId,
        name,
        color
      );
    }
    return this.calendarEvents[calendarId];
  },
  getNew(calendarId, colorCode) {
    const base = this.get(calendarId);
    const color = colors.find(({ color }) => color === colorCode);
    return new CalendarEvent(base.calendarId, base.name, color);
  },
};
