import Vue from "vue";
import { signIn } from "../../network/init";
import VModal from "vue-js-modal";
import Img from "../../assets/logo__img.png";

Vue.use(VModal);

export default {
  name: "TheLogin",
  methods: {
    logIn() {
      signIn().then(() => {
        this.$modal.hide("TheModalConnectionLoading");
      });
      this.$modal.show("TheModalConnectionLoading");
    },
  },
  render() {
    const loginStatus = this.$store.state.loginError;
    let errorTxt = "";
    if (loginStatus.message && loginStatus.message === "popup_closed_by_user") {
      errorTxt = "認証に失敗しました。";
      this.$modal.hide("TheModalConnectionLoading");
    }
    return (
      <div class="p-login">
        <div onClick={this.logIn}>
          <h1 class="c-logo__img">
            <img src={Img} alt="Leverages" />
          </h1>
          <div class="c-login-box">
            <p class="c-login__ttl">サインインしてください。</p>
            {!loginStatus.success && (
              <p class="c-login__error-txt is-error">{errorTxt}</p>
            )}
            <p class="c-login-btn c-input-btn is-active">サインイン</p>
          </div>
        </div>
        <span class="app-version">{APP_VERSION}</span>
      </div>
    );
  },
};
