export default {
  name: "CardTypesStandByTimeAlert",
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timer: -1,
      edit: false,
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  render() {
    const none = {
      display: "none",
    };
    const readyIn = this.$store.state.standByEventCheckIn;
    const autoCancelTime = this.$store.getters.cancelCheckTime;
    this.$store.dispatch("statusTimer", {
      status: this.event.start_time,
    });
    const now = new Date().getTime();
    const nEvStandBy =
      this.event.start_time &&
      new Date(this.event.start_time.replace(/-/g, "/"));
    const nEvStandByMins = nEvStandBy.getMinutes() + autoCancelTime;
    nEvStandBy.setMinutes(nEvStandByMins);
    let setTime = new Date(new Date(nEvStandBy).getTime() - now).getMinutes();
    this.timer = setInterval(() => {
      setTime--;
      if (setTime < 0) {
        this.$store.commit("updateCalenderscedules", {
          type: "reserveCancel",
          data: this.event.calendar_id,
        });
        setTime = 0;
        clearInterval(this.timer);
      }
      this.$el.querySelector(".c-myreserve__info_time").innerHTML = setTime;
    }, 60 * 1000);
    return (
      <p class="c-myreserve__info" style={!readyIn && none}>
        予定時刻になりました。
        <br />
        あと<span class="c-myreserve__info_time">{setTime}</span>
        分で自動でキャンセルになります。
      </p>
    );
  },
};
