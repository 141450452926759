import CandidateRoom from "./CandidateRoom";
import MeetSwitch from "./meetSwitch";
import VisibilitySelect from "./visibilitySelect";
import TransparencySelect from "./transparencySelect";

const texts = { たたむ: "開く", 開く: "たたむ" };

export default {
  name: "TheCandidateRooms",
  data() {
    return {
      isOpen: true,
      text: "開く",
      cds: [],
    };
  },
  computed: {
    candidates() {
      return this.$store.getters.candidate;
    },
  },
  methods: {
    changes(e) {
      this.text = texts[this.text];
      this.isOpen = this.text === "開く";
    },
    handleClick(target) {
      if (!target.selected) target.type = "list";
      this.$set(target, "selected", !target.selected);
      this.$store.state.candidateRooms = [...this.$store.state.candidateRooms];
    },
  },
  render() {
    const selectedCandidates = this.$store.getters.selectedCandidates;
    return (
      <div id="js_select-room_wrap">
        <div class="p-select-room">
          <div
            ref="toggleArea"
            class={[
              "c-select-room__box-wrap ac",
              { "no-active": !this.isOpen },
            ]}
          >
            <input id="ac4" type="checkbox" checked />
            <label for="ac4" class="" id="labelGuest">
              <div
                class={["c-select-room__box-ttl", { "is-active": this.isOpen }]}
                onClick={(e) => this.changes(e)}
              >
                会議室予約
                <div class="c-select-room__box-sub__ttl r-select-room__iconUpDonw-wrap">
                  <span ref="openText" class="openTxt">
                    {this.text}
                  </span>
                  <p
                    class={[
                      "c-select-room__box-svg",
                      "r-select-room__iconUpDonw",
                      { "is-open": !this.isOpen },
                    ]}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </p>
                </div>
              </div>
            </label>
            <div
              class="c-select-room__box te animateOverWrapBox"
              ref="acContent1"
              onTransitionstart={(e) => e.target.classList.remove("te")}
              onTransitionend={(e) => e.target.classList.add("te")}
            >
              <p
                class="c-floor__btn"
                onClick={() => this.$store.commit("shiftFloors")}
              >
                {this.$store.state.floor}
              </p>
              <p class="c-modal-content__sub-ttl">会議室を選択してください。</p>
              <div class="c-select-room__main-wrap">
                <ul class="c-select__ttl-list  abcList-ul">
                  {this.candidates.map((candidate, i) => {
                    if (!candidate)
                      return (
                        <li data-index={i}>
                          <span>{["A", "B", "C"][i]}</span>
                        </li>
                      );
                    return (
                      <li
                        class={{
                          "is-active": candidate.selected,
                          "is-old": candidate.start < new Date(),
                        }}
                        data-index={i}
                        onClick={() => candidate && this.handleClick(candidate)}
                      >
                        <span>{["A", "B", "C"][i]}</span>
                      </li>
                    );
                  })}
                </ul>
                <ul class="c-select-list c-modal-list">
                  {this.candidates.map((candidate, index) => {
                    if (!candidate) return <div />;
                    return (
                      <CandidateRoom
                        candidate={candidate}
                        index={index}
                        onClick={(e) => {
                          e.target.classList.contains("selected-area") &&
                            candidate &&
                            this.handleClick(candidate);
                        }}
                      />
                    );
                  })}
                </ul>
              </div>
              <div class="c-plan-textarea__wrap">
                <div class="c-plan-textarea__svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M3 18h12v-2H3v2zM3 6v2h18V6H3zm0 7h18v-2H3v2z" />
                    <path d="M0 0h24v24H0V0z" fill="none" />
                  </svg>
                </div>
                <textarea
                  class="c-plan-textarea"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="説明を入力"
                />
              </div>
              <div class="c-plan-textarea__wrap">
                <MeetSwitch editable={true} meetflg={0} event={null} />
              </div>
              <div class="c-plan-textarea__wrap">
                <div class="c-plan-textarea__svg">
                  <svg
                    focusable="false"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    class=" NMm5M"
                  >
                    <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zM10 4h4v2h-4V4zm10 15H4V8h16v11z"></path>
                  </svg>
                </div>
                <div class="c-search-new-select__box" style="width: 130px;">
                  <TransparencySelect editable={true} defval="opaque" />
                </div>
                <span class="c-search-new-select-while" style="padding: 0 5px;">
                  {" "}
                </span>
                <div class="c-search-new-select__box" style="width: 150px;">
                  <VisibilitySelect editable={true} defval="default" />
                </div>
              </div>
              <div
                class="c-modal-btn__wrap"
                style={{ display: this.candidates.length === 0 ? "none" : "" }}
              >
                {selectedCandidates.length >= 1 && (
                  <input
                    type="button"
                    class="c-input-btn is-active c-reserve__btn js_disabled_reserve_btn"
                    value="仮予約"
                    onClick={this.$listeners.click}
                  />
                )}
                {selectedCandidates.length === 1 && (
                  <input
                    type="button"
                    class="c-input-btn is-active c-reserve__btn js_disabled_reserve_btn"
                    value="予約"
                    onClick={this.$listeners.click}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
