import parseDate from "utils/parseDate";
import { putSchedules } from "network/server";

export default {
  name: "CardCommonButton",
  props: {
    event: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      checkIn: false,
    };
  },
  methods: {
    show(e) {
      let type = e === "checkIn" ? "standBy" : "checkOut";
      if (e === "cancel") type = "cancel";
      this.$store.state.storeReservationCancel = this.event;
      this.$store.commit("updateModalBtn", type);
      this.$modal.show("TheModalMyReserveCheck");
    },
    extension() {
      document
        .querySelector(".c-myreserve-box.is-inuse")
        .classList.add("extentTime");
      this.$modal.show("TheModalCardInUsedExtend");
    },
    async update() {
      const year = document
        .querySelector(".editActive .myreserveDatePicker input")
        .value.replace(/[^0-9]/g, "/")
        .slice(0, 10);
      const yearForPut = document
        .querySelector(".editActive .myreserveDatePicker input")
        .value.replace(/[^0-9]/g, "-")
        .slice(0, 10);
      const start = document.querySelector(
        ".editActive .c-myreserve-daytime__time-in"
      ).dataset.selectVal;
      const end = document.querySelector(
        ".editActive .c-myreserve-daytime__time-out"
      ).dataset.selectVal;
      const members = [
        ...document.querySelectorAll(".editActive .c-guest-join__output li"),
      ]
        .filter((el) => el.dataset.email)
        .map((el) => ({
          email: el.dataset.email,
          color_code: el.dataset.color,
        }));
      const summary = document.querySelector(
        ".editActive .c-myreserve-candidate-ttl"
      ).value;
      const description = document.querySelector(
        ".editActive .c-plan-textarea.activated"
      ).value;
      const updateData = {
        title: summary,
        detail: description,
        yearCheck: year,
        start: start,
        end: end,
        guests: members.map(({ email }) => email),
      };
      const format = (setYear, day) => {
        const { hours, minutes } = parseDate(new Date(day));
        return `${setYear} ${hours}:${minutes}:00`;
      };
      try {
        this.$store.state.isLoading = true;
        await putSchedules({
          schedule_uuid: this.event.mySch.uuid,
          summary,
          description,
          members,
          rooms: [
            {
              event_id: this.event.eventId,
              update: {
                start_time: format(yearForPut, start),
                end_time: format(yearForPut, end),
              },
            },
          ],
        }).then((res) => (res.success ? res : Promise.reject(res)));

        if (this.event.status === "予約")
          this.$store.commit("updateCalenderscedules", {
            type: "updateReserve",
            data: [updateData, this.event.calendar_id],
          });
        if (this.event.status === "使用中")
          this.$store.commit("updateCalenderscedules", {
            type: "updateInUsed",
            data: updateData,
          });
        this.$store.dispatch("loadEvents").then(() => {
          const editBtnActive = document.querySelector(
            ".editActive .c-myreserve-edit__btn.is-active"
          );
          editBtnActive && editBtnActive.click();
          this.$store.state.storeReservationUpdateState = true;
          this.$store.state.isLoading = false;
        });
      } catch (e) {
        if (!e.success) {
          if (e.message?.includes("Not available during that time")) {
            alert("使用できない時間帯です。");
          } else {
            alert(e.error || e.message);
          }
        }
        this.$store.state.isLoading = false;
      }
    },
  },
  render() {
    const now = this.now;
    const start = this.event.start_time;
    const btn = () => {
      const standBy = {
        status: "checkIn",
        txt: "チェックイン",
        btn: "予約をキャンセル",
      };
      const inUsed = {
        status: "extention",
        txt: "延長",
        btn: "チェックアウト",
      };
      if (this.status === "standBy") return standBy;
      if (this.status === "inUsed") return inUsed;
    };
    const fiveAhead = new Date(start).getTime() + 300000;
    if (
      new Date(fiveAhead) <= new Date(now) &&
      new Date(now) <= new Date(start)
    ) {
      this.checkIn = true;
    }
    const readyIn = this.$store.state.standByEventCheckIn;
    const style = {
      pointerEvents: "none",
      background: "#ccc",
    };
    const allowChechIn = this.$store.getters.spCheckIn;
    const allowChechOut = this.$store.getters.spCheckOut;
    const statusStand = this.status === "standBy";
    const statusUsed = this.status === "inUsed";
    return (
      <div>
        {!this.editable && (
          <div class="c-myreserve__btn-box">
            {statusStand && (
              <p
                class="c-btn c-myreserve__btn"
                onClick={() => this.show("cancel")}
              >
                {btn().btn}
              </p>
            )}
            {allowChechIn && statusStand && (
              <p
                class="c-btn c-myreserve__btn checkInBtn"
                style={!readyIn && style}
                onClick={() => this.show("checkIn")}
              >
                {btn().txt}
              </p>
            )}

            {allowChechOut && statusUsed && (
              <p
                class="c-btn c-myreserve__btn"
                onClick={() => this.show("checkOut")}
              >
                {btn().btn}
              </p>
            )}
            {statusUsed && (
              <p class="c-btn c-myreserve__btn" onClick={this.extension}>
                {btn().txt}
              </p>
            )}
          </div>
        )}
        {this.editable && (
          <div
            class="c-myreserve__btn-box"
            style="justify-content: space-around;"
          >
            <p
              class="c-btn c-myreserve__btn"
              style="margin-right: 0;"
              onClick={this.update}
            >
              保存
            </p>
          </div>
        )}
      </div>
    );
  },
};
