import store from "store";
import formatDate from "utils/formatDate";

export const registration = (ownerCalendarEvent, params) => {
  const rooms = store.getters.selectedCandidates.map((room) => ({
    room_id: room.id,
    start_time: formatDate(room.start),
    end_time: formatDate(room.end),
    color: store.state.color.code,
  }));

  const owner = {
    email: ownerCalendarEvent.calendarId,
    color_code: ownerCalendarEvent.color.color,
  };

  return { rooms, owner, ...params };
};

export const extension = (id, upSt, upEd, status) => {
  try {
    return {
      rooms: [
        {
          event_id: id,
          update: {
            start_time: upSt,
            end_time: upEd,
            status: status,
          },
        },
      ],
    };
  } catch (e) {
    store.commit("updateConnectError", true);
    alert("通信エラーが発生しました。");
  }
};
