import store from "store";
import * as gas from "./server";
import { getEmail } from "./google";
import token from "network/token";
import { getToken } from "./server";
import { router } from "router";

const getConnection = () => {
  const promises = [];
  const email = getEmail();
  store.commit("email", email);
  store.state.loginStatus = true;

  const cacheItems = {
    rooms: gas.getRooms,
    staffs: gas.getStaffs,
    tags: gas.getTags,
    options: gas.getOptions,
  };

  for (const key of Object.keys(cacheItems)) {
    const items = localStorage.getItem(key);
    const request = cacheItems[key]().then((res) => {
      store.commit(key, res);
    });
    if (items) {
      store.commit(key, JSON.parse(items));
    } else {
      promises.push(request);
    }
  }

  if (!router.currentRoute.name || router.currentRoute.name === "login") {
    router.push({ name: "roomReservation" }).catch((e) => {
      console.warn("initial move error", e);
    });
  }

  const promise = Promise.all(promises);

  promise.then(() => {
    store.dispatch("loadEvents", { date: Date.now(), days: 7 });
    const reRequest = () => {
      setTimeout(() => {
        if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
          const requests = Object.keys(cacheItems).map((key) => {
            return cacheItems[key]().then((res) => store.commit(key, res));
          });

          Promise.all(requests).then(reRequest);
        }
      }, 60000);
    };
    reRequest();
  });

  return promise;
};

const config = () => ({
  client_id: `${GOOGLE_CLIENT_ID}`,
  scope: "profile email https://www.googleapis.com/auth/calendar.events",
});

export const signIn = async () => {
  const auth2 = gapi.auth2.getAuthInstance();
  if (auth2.isSignedIn.get() && localStorage.getItem("accessToken")) return;
  try {
    const { code } = await auth2.grantOfflineAccess(config());
    const res = await getToken(code);
    store.state.loginStatus = true;
    token.set(res.accessToken);
  } catch (e) {
    store.commit("updateLoginError", {
      success: false,
      message: e.error,
    });
  }
};

export function init() {
  gapi.load("client:auth2", () => {
    gapi.auth2.init(config()).then(() => {
      const auth2 = gapi.auth2.getAuthInstance();
      auth2.isSignedIn.listen((bool) => bool && getConnection());
      if (localStorage.getItem("accessToken") && auth2.isSignedIn.get())
        return auth2.then(getConnection);
      if (router.currentRoute.name !== "login") {
        router.push({ name: "login" }).catch((e) => {
          console.warn("login move error", e);
        });
      }
      store.state.loginStatus = false;
      if (auth2.isSignedIn.get()) auth2.signOut();
    });
  });
}
