export default {
  name: "OneColor",
  props: {
    color: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    return (
      <div
        class={["c-plan-option", { "is-active": this.active }]}
        style={{ background: this.color.color }}
        data-color={this.color.color}
        data-color-index={this.color.colorIndex}
        aria-label={this.color.text}
        role={this.color.role}
        onClick={this.$listeners.click}
      >
        <span class="c-paln-option__check" />
        <div
          class="c-plan-option__inner"
          data-color={this.color.color}
          data-text={this.color.text}
          data-colorid={this.color.colorId}
        />
      </div>
    );
  },
};
