import { getHoliday } from "network/google";

export default class PublicHoliday {
  constructor() {
    this.done = false;
    getHoliday().then((items) => {
      this.items = items.map((item) => {
        const ymd = item.start.date.split("-").map(Number);
        return new Date(ymd[0], ymd[1] - 1, ymd[2]);
      });
      this.done = true;
    });
  }

  isHoliday(date) {
    const sy = date.getFullYear();
    const sm = date.getMonth();
    const sd = date.getDate();

    return this.items.some((item) => {
      const iy = item.getFullYear();
      const im = item.getMonth();
      const id = item.getDate();
      return iy === sy && im === sm && id === sd;
    });
  }
}
