import CardCommonDetailTime from "../../../common/CardCommonDetailTime";

export default {
  name: "CardTypesTentativeEvent",
  props: {
    event: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hide: false,
    };
  },
  render() {
    const thisEv = this.event[this.index];
    const roomDetail =
      thisEv &&
      this.$store.state.rooms.filter(
        (detail) => detail.id === Number(thisEv.room_id)
      );
    const tagsArr = [];
    thisEv &&
      this.$store.state.tags.forEach((tag) => {
        roomDetail[0].tags.forEach((num) => {
          if (tag.id === num) tagsArr.push(tag.name);
        });
      });
    const adjust = thisEv && { ev: thisEv };
    if (this.hide && !this.editable) this.hide = false;
    const cancel = () => {
      this.hide = true;
      this.$store.state.tentativeUpdateId.push(thisEv.calendar_id);
    };
    const findTentative = this.event.find((n) => n.status === "仮予約");
    if (!findTentative)
      this.$store.commit("updateCalenderscedules", {
        type: "cancelAllTentative",
        data: [thisEv.calendar_id],
      });

    return (
      <li
        class={[
          "u-ttl01 tentativeList-li",
          thisEv && thisEv.status === "キャンセル" && "thisCanceled",
        ]}
        style="display: block; padding: 0; margin-bottom: 10px; min-height: 88px;"
      >
        <div class="roomRecommendsAlphabet">
          <span>{["A", "B", "C"][this.index]}</span>
        </div>
        {thisEv && thisEv.status !== "キャンセル" && (
          <div
            class="border-wrap is-selected"
            style={this.hide && this.editable && "display: none"}
          >
            <div class="c-select-info">
              <div
                class="c-select-info__tooltips c-label-tooltips-item selected-area"
                ontouchend
              >
                <span class="c-label-tooltips selected-area">
                  {roomDetail[0].name}
                </span>
                <h4
                  class="c-select-info__room"
                  onClick={(e) =>
                    e.target.offsetWidth !== e.target.scrollWidth &&
                    e.target.parentNode.classList.toggle("is-active")
                  }
                >
                  {roomDetail[0].name}
                </h4>
              </div>
              {tagsArr.map((tagName) => {
                return <div class="c-select-info__name">{tagName}</div>;
              })}
              <p class="c-select-info__people">
                <span class="c-select-info__people-number">
                  {roomDetail[0].capacity}
                </span>
                人
              </p>
            </div>
            <CardCommonDetailTime
              event={adjust}
              editable={this.editable}
              type="仮予約"
            />
          </div>
        )}
        {thisEv && thisEv.status !== "キャンセル" && this.editable && (
          <div
            class="c-select__release-btn__wrap"
            onClick={cancel}
            style={this.hide && "display: none"}
          >
            <p class="c-select__release-btn is-active">キャンセル</p>
          </div>
        )}
      </li>
    );
  },
};
