import CardTypesTentativeTitle from "./types/tentative/CardTypesTentativeTitle";
import CardTypesTentativeButton from "./types/tentative/CardTypesTentativeButton";
import CardTypesTentativeEditButton from "./types/tentative/CardTypesTentativeEditButton";
import CardTypesTentativeDetail from "./types/tentative/CardTypesTentativeDetail";
import CardTypesTentativeCancel from "./types/tentative/CardTypesTentativeCancel";

export default {
  name: "CardTentative",
  props: {
    event: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    beforeLeave: {
      type: Function,
      required: true,
    },
    leave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      icon: false,
      cancel: false,
      edit: false,
      detailOpen: "close",
    };
  },
  methods: {
    before(el) {
      if (this.event.status === "仮予約") {
        return el.classList.remove("animated");
      }
      this.beforeLeave(this.$el);
    },
    after() {
      if (this.event.status === "仮予約") return;
      const baseSchedule = JSON.parse(this.$store.state.stockJsonTentative);
      const newSchedule = baseSchedule.filter(
        ({ uuid }) => uuid !== this.event.mySch.uuid
      );
      this.$store.state.stockJsonTentative = JSON.stringify(newSchedule);
      this.leave();
    },
  },
  render() {
    const editAct = () => {
      if (this.edit) {
        this.edit = false;
      } else {
        const editBtnActive = document.querySelector(
          ".editActive .c-myreserve-edit__btn.is-active"
        );
        editBtnActive && editBtnActive.click();
        this.edit = !this.edit;
      }
      document
        .querySelector(".tentativeList-wrap .c-myreserve-join__output")
        .click();
    };
    const detailOpen = () =>
      (this.detailOpen = this.detailOpen === "open" ? "close" : "open");
    const isOwner = this.event.googleCal.creator === this.$store.state.email;
    return (
      <transition
        name="standByList"
        tag="div"
        leave-active-class="animated fadeOutLeft"
        onBefore-leave={this.before}
        onAfter-leave={this.after}
      >
        {this.event.status !== "キャンセル" && (
          <div
            class={[
              "c-myreserve-box is-reservation tentativeList-wrap",
              this.edit && "tentativeEditActive",
            ]}
          >
            {isOwner && (
              <CardTypesTentativeEditButton
                onClick={editAct}
                editable={this.edit}
              />
            )}
            <CardTypesTentativeTitle cancel={this.cancel} />
            <CardTypesTentativeDetail
              onClick={detailOpen}
              index={this.index}
              event={this.event}
              editable={this.edit}
              isOwner={isOwner}
            />
            <div class="c-myreserve__btn-box">
              {isOwner && !this.edit && (
                <CardTypesTentativeCancel event={this.event} />
              )}
              {isOwner && this.edit && (
                <CardTypesTentativeButton event={this.event} />
              )}
            </div>
          </div>
        )}
      </transition>
    );
  },
};
