import TheCalenderTopDates from "./TheCalenderTopDates";
import TheCalendarRooms from "./rooms/TheCalendarRooms";
import TheCalendarTimeScale from "./TheCalendarTimeScales";
import dragging from "states/dragging";

export default {
  name: "TheCalendar",
  props: {
    date: Date,
  },
  data() {
    return {
      move: 0,
      isInitialScrolled: false,
    };
  },
  mounted() {
    this.$store.watch(
      (state) => state.floor,
      () => {
        this.$forceUpdate();
      }
    );
    // dragging.onChangeMove = (bool) => {
    //   this.$refs.scrollElement.style.overflowY = bool ? 'hidden' : ''
    // }
  },
  updated() {
    if (!this.isInitialScrolled) this.defaultScroll();
  },
  methods: {
    times() {
      return {
        select: this.$refs.time.$refs.times,
        width: this.$refs.time.$refs.times.clientWidth,
        length: this.$refs.time.$refs.time.querySelectorAll(
          ".c-calendar__table-sub__time"
        ).length,
      };
    },
    handleScroll(e) {
      if (this.$refs.rooms === undefined) return;
      const target = e.target;
      const scroll = target.scrollLeft;
      if (scroll !== this.move && e.type === "scroll") {
        return;
      }
      const time = this.$refs.time.$refs.time;
      if (time !== target && scroll !== time.scrollLeft) {
        time.scrollLeft = scroll;
      }
      this.$refs.rooms.$refs.spaces.scrollLeft = scroll;
    },
    scrollTime(e) {
      this.$refs.time.$refs.times.scrollLeft = e.target.scrollLeft;
    },
    defaultScroll() {
      const times = this.times();
      const maxScroll = times.select.scrollWidth - times.select.clientWidth;
      const nowHours = new Date().getHours();
      this.move = Math.min(
        Math.round((times.select.scrollWidth / times.length) * nowHours * 2),
        maxScroll
      );
      times.select.scrollLeft = this.move;
      const ev = new Event("scroll");
      times.select.dispatchEvent(ev);
      this.isInitialScrolled = true;
    },
  },
  render() {
    const rooms = this.$store.state.rooms;

    const handleInput = (start) => {
      const end = new Date(start);
      end.setDate(end.getDate() + 1);
      this.$store.commit("updateDefault", start);
      this.$store.dispatch("loadEvents", {
        date: start,
        days: 1,
      });
    };

    const reset = () => {
      dragging.reset();
      // this.$refs.scrollElement.style.overflowY = ''
    };

    let timer, top;
    return (
      <div class={["p-calendar__wrap", "u-box01"]}>
        <TheCalenderTopDates ref="cd" date={this.date} onInput={handleInput} />
        <div class="c-calendar__table-wrap">
          <TheCalendarTimeScale ref="time" onScroll={this.handleScroll} />
          <div
            ref="scrollElement"
            class="c-calendar__table-main__wrap"
            onTouchmove={(e) => {
              if (
                (dragging.isMoved || dragging.scrollMode === "horizontal") &&
                e.cancelable
              )
                return e.preventDefault();
            }}
            onScroll={(e) => {
              if (dragging.isMoved || dragging.scrollMode === "horizontal")
                return e.preventDefault();
              dragging.scrollMode = "vertical";
              if (timer) return;
              top = e.target.scrollTop;
              timer = setTimeout(() => {
                timer = null;
                if (dragging.isMoved) return;
                if (Math.abs(top - e.target.scrollTop) > 30) {
                  clearTimeout(dragging.timer);
                  dragging.isScrolled = true;
                }
              }, 300);
            }}
            onTouchend={reset}
            onTouchcancel={reset}
          >
            <TheCalendarRooms
              ref="rooms"
              date={this.date}
              rooms={rooms}
              onScroll={this.scrollTime}
            />
            {rooms.length === 0 && (
              <div
                class="empty__c-calendar__table-main"
                style="margin: 10px; border: 1px solid #333333; border-radius: 10px;"
              >
                <h1 style="text-align: center; padding-bottom: 7px;">
                  <p style="font-size: medium; ">検索結果はありませんでした</p>
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
};
