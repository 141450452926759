import { putSchedules } from "network/server";
import { calenderDelete } from "network/google";
import parseDate from "utils/parseDate";
import { findColorById, findColorByCode } from "data/colors";

const baseCancel = { update: { status: "キャンセル" } };

const format = (setYear, day) => {
  const { hours, minutes } = parseDate(new Date(day));
  return `${setYear} ${hours}:${minutes}:00`;
};

export default {
  name: "CardTypesTentativeButton",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async update() {
      try {
        this.$store.state.isLoading = true;

        const color = isFinite(this.event.googleCal.color)
          ? findColorById(this.event.googleCal.color)
          : findColorByCode(this.event.googleCal.color);

        const members = [
          ...document.querySelectorAll(
            ".tentativeEditActive .c-guest-join__output li"
          ),
        ]
          .filter((el) => el.dataset.email)
          .map((el) => ({
            email: el.dataset.email,
            color_code: el.dataset.color,
          }));
        const guests = members.map(({ email }) => email);
        const summary = document.querySelector(
          ".tentativeEditActive .c-myreserve-candidate-ttl"
        ).value;
        const description = document.querySelector(
          ".tentativeEditActive .c-plan-textarea"
        ).value;

        const updateEvents = this.event.mySch.schedule.events
          .map((ev, i) => {
            const target = document.querySelectorAll(
              ".tentativeEditActive .tentativeList-li"
            )[i];
            if (!target) return null;
            const currentUpdateEvent = {};
            const deleteTentativeId = this.$store.state.tentativeUpdateId.find(
              (deleteId) => deleteId === ev.calendar_id
            );

            // 削除
            if (deleteTentativeId !== undefined) {
              currentUpdateEvent.commitData = {
                type: "cancelTentative",
                data: [deleteTentativeId],
              };
              currentUpdateEvent.putSchedule = {
                event_id: ev.id,
                ...baseCancel,
              };
              currentUpdateEvent.putGoogleCalendarEvent = false;
              currentUpdateEvent.deleteCalendarEvent = {
                calendarId: this.$store.state.email,
                eventId: ev.calendar_id,
              };
              return currentUpdateEvent;
            }

            // 更新
            const yearCheck = target
              .querySelector(".myreserveDatePicker input")
              .value.replace(/[^0-9]/g, "/")
              .slice(0, 10);
            const yearCheckForPut = target
              .querySelector(".myreserveDatePicker input")
              .value.replace(/[^0-9]/g, "-")
              .slice(0, 10);

            const start = target.querySelector(".c-myreserve-daytime__time-in")
              .dataset.selectVal;
            const end = target.querySelector(".c-myreserve-daytime__time-out")
              .dataset.selectVal;
            const room = this.$store.state.rooms.find(
              (r) => r.id === Number(ev.room_id)
            );

            const updateData = {
              title: summary,
              detail: description,
              yearCheck,
              start,
              end,
              guests,
            };

            currentUpdateEvent.commitData = {
              type: "updateTentative",
              data: [updateData, ev.calendar_id],
            };
            currentUpdateEvent.putSchedule = {
              event_id: ev.id,
              update: {
                start_time: format(yearCheckForPut, start),
                end_time: format(yearCheckForPut, end),
              },
            };
            currentUpdateEvent.putGoogleCalendarEvent = {
              calenderId: this.$store.state.email,
              eventId: ev.calendar_id,
              color: color?.colorId,
              location: room.name,
              ...{
                ...updateData,
                guests: [...new Set([room.resource_id, ...updateData.guests])],
              },
            };
            currentUpdateEvent.deleteCalendarEvent = false;

            return currentUpdateEvent;
          })
          .filter(Boolean);

        const meetflg = this.$store.state.meetflg;
        const meetid = this.$store.state.meetid;
        const transparency = this.$store.state.transparency;
        const visibility = this.$store.state.visibility;

        const res = await putSchedules({
          schedule_uuid: this.event.mySch.uuid,
          summary,
          description,
          members,
          rooms: updateEvents.map((ev) => ev.putSchedule),
          meetflg,
          meetid,
          transparency,
          visibility,
        });

        if (!res.success) {
          if (res.message?.includes("Not available during that time")) {
            alert("使用できない時間帯です。");
          } else {
            alert(res.message);
          }
          this.$store.state.isLoading = false;
          return;
        }

        const updates = updateEvents.map((ev) => {
          if (ev.deleteCalendarEvent) {
            return calenderDelete(ev.deleteCalendarEvent);
          } else {
            return Promise.resolve();
          }
        });

        await Promise.all(updates);

        updateEvents.forEach((ev) => {
          this.$store.commit("updateCalenderscedules", ev.commitData);
        });

        await this.$store.dispatch("loadEvents").then(() => {
          const closeEdit = document.querySelector(
            ".tentativeEditActive .c-myreserve-cancel__btn"
          );
          closeEdit && closeEdit.click();
        });
      } catch (e) {
        alert(e.message || e.error || "エラーが発生しました。");
      } finally {
        this.$store.state.isLoading = false;
      }
    },
  },
  render() {
    return (
      <p
        class="c-btn c-myreserve__btn"
        style="margin: 0 auto; width: 145px;"
        onClick={this.update}
      >
        保存
      </p>
    );
  },
};
